import React from 'react';
import {
  Box,
  Typography,
  Link,
  Grid2 as Grid,
  
} from '@mui/material';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded'; // Import the icon
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { WhatsApp } from '@mui/icons-material';

const CustomerSupportSection = () => {
  

  return (
    <Box sx={{ bgcolor: '#e7eef5', py: 3 }}>
      <Box sx={{ maxWidth: 1200, mx: 'auto', px: 2 }}>
        <Grid
          container
          direction={'row'}
          alignItems="center"
          justifyContent="center"
          spacing={2}
          mb={3}
        >
          <Grid item xs={12} sm="auto" display="flex" alignItems="center">
            {/* Replace image with SupportAgentRoundedIcon */}
            <SupportAgentRoundedIcon sx={{ fontSize: 50, color: '#6D99C5', mr: 2 }} />
            <Box>
              <Typography variant="h6" fontWeight="bold" className='font-reem' >
                24/7 Customer Support
              </Typography>
              <Typography variant="body2">
                Speak to Asma or another travel expert
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm="auto" >
            <Link href="tel:+9221111172782" color="primary" underline="hover" display="block">

              <Typography>
                <PhoneEnabledOutlinedIcon /> &nbsp;Call
              </Typography>
              <Typography>
                +92 21-111-172-782
              </Typography>
            </Link>
          </Grid>
          {/* Contact Links */}
          <Grid item xs={12} sm="auto" >
            <Link href="https://wa.me/923047772782" color="primary" underline="hover" display="block">
              <Typography>
                <WhatsApp /> &nbsp; WhatsApp
              </Typography>
              <Typography>
                +92 304 777 2782
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CustomerSupportSection;
