import React from 'react';
import { Router, Route, Switch } from 'wouter'; // Switch ensures only one route is rendered at a time
import HomePage from './pages/Home/HomePage';
import FlightPage from './pages/Flight/FlightSearch';
import Footer from './components/Footer';
import Header from './components/Header';
import ContactUs from './pages/ContactUs/ContactUsPage';
import NotFound from './pages/NotFound/NotFoundPage';
import SearchBooking from './pages/SearchBooking/SearchBookingPage';
import TermsAndConditions from './pages/TravelTermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import FAQS from './pages/FAQS/FAQS';
import SignInDialog from './pages/SignIn/SignInPage';
import AboutUsPage from './pages/AboutUs/AboutUsPage';
import ManageBooking from './pages/ManageBookings/ManageBooking';
import Payment from './pages/ManageBookings/components/Payment';
import UserProfile from './pages/UserProfile/userProfile';
import SastaRefund from './pages/SastaRefund/SastaRefund';


const App = () => {
  return (
    <div>
      <Header />
      <Router>
        <Switch>
          <Route path="/" component={HomePage} />
          <Route path="/flight" component={FlightPage} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/aboutus" component={AboutUsPage} />
          <Route path="/search-booking" component={SearchBooking} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/privacypolicy" component={PrivacyPolicy} />
          <Route path="/faqs" component={FAQS} />
          <Route path="/signIn" component={SignInDialog} />
          <Route path="/manage-booking" component={ManageBooking} />
          <Route path="/user-profile" component={UserProfile} />
          <Route path="/sastarefund" component={SastaRefund} />
          <Route path="/payment" component={Payment} />
         
          
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
