/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Paper,
  Autocomplete,
  Dialog,
} from "@mui/material";
import {Search, Person, ArrowDropDown, Close } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import ClassSelector from "../Flight/ClassSelector";
import TravelersSelection from "../Flight/TravelersSelection";
import { handleNavigation } from "../../Utils/utils-functions";
import useIsMobile from "../../reusablecomponents/useIsMobile";


const airports = [
  { label: "Islamabad", code: "ISB" },
  { label: "Lahore", code: "LHE" },
  { label: "Dubai", code: "DXB" },
  { label: "Karachi", code: "KHI" },
];

const FlightSearchResults = ({ flightSearchPage }) => {
  const [classType, setClassType] = useState("Economy");
  const [passengers, setPassengers] = useState(1);
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [departureDate, setDepartureDate] = useState("2024-12-04");
  const [returnDate, setReturnDate] = useState("");
  const [tripType, setTripType] = useState("One Way");
  const [isTravelersModalOpen, setIsTravelersModalOpen] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const isMobile = useIsMobile();
  const [multiCityFlights, setMultiCityFlights] = useState([
    { fromLocation: null, toLocation: null, departureDate: "2024-12-04" },
    { fromLocation: null, toLocation: null, departureDate: "" }
  ]);
  const gridWidth = flightSearchPage ? (tripType === "Multi City" ? "25%" : "16%") : "22%";

  const handleSearch = () => {
    console.log({
      tripType,
      fromLocation,
      toLocation,
      departureDate,
      returnDate,
      passengers,
      classType,
    });
    handleNavigation("/flight");
  };

  const handleTravelersUpdate = (updatedAdults, updatedChildren, updatedInfants) => {
    setAdults(updatedAdults);
    setChildren(updatedChildren);
    setInfants(updatedInfants);
    setPassengers(updatedAdults + updatedChildren + updatedInfants);
  };

  const handleFlightChange = (index, field, value) => {
    const updatedFlights = [...multiCityFlights];
    updatedFlights[index][field] = value;
    setMultiCityFlights(updatedFlights);
  };

  const handleRemoveFlight = (index) => {
    const updatedFlights = multiCityFlights.filter((_, i) => i !== index);
    setMultiCityFlights(updatedFlights);
  };

  const totalTravelers = adults + children + infants;

  const travelerText =
    totalTravelers === adults
      ? `${adults} ${adults > 1 ? "Adults" : "Adult"}`
      : `${totalTravelers} Travelers`;

  const renderFlightFields = (flight, index) => (
    <>
      {tripType === "Multi City" && (
        <Grid item sx={{width: { xs: '100%',sm: "20%", md: flightSearchPage ? "10%" : "12%" }, justifyContent: {xs: "space-between"}, display: "flex" }}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {`Flight ${index + 1}`}
          </Typography>
          {isMobile && index < 5 && tripType === "Multi City" && (
        <Grid item sx={{ width: { sm: "5%", md: flightSearchPage ? "2%" : "4%" } }}>
          {index >= 2 && (
            <IconButton onClick={() => handleRemoveFlight(index)}>
              <Close />
            </IconButton>
          )}
        </Grid>
      )}
        </Grid>
      )}

      <Grid item sx={{ width: { xs: '100%',sm: "40%", md: gridWidth } }}>
        <Autocomplete
          options={airports}
          getOptionLabel={(option) => `${option.label} (${option.code})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Flying From"
              placeholder="City or Airport"
              size="small"
            />
          )}
          value={flight.fromLocation}
          onChange={(event, newValue) => handleFlightChange(index, 'fromLocation', newValue)}
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </Grid>
      <Grid
        item
        sx={{ width: {sm: "5%", md: "2%" } }}
        container
        justifyContent="center"
      >
        <IconButton color="primary" sx={{borderRadius: "50%",boxShadow:"0px 1px 4px rgba(0, 0, 0, 0.25)",transform: {xs: "rotate(90deg)"}, margin: {xs: "-14px 0px -14px 0px" } }}>
          <img src="images/convert.svg" alt="from/to" />
        </IconButton>
      </Grid>
      <Grid item sx={{ width: {xs: '100%', sm: "40%", md: gridWidth } }}>
        <Autocomplete
          options={airports}
          getOptionLabel={(option) => `${option.label} (${option.code})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Flying To"
              placeholder="City or Airport"
              size="small"
            />
          )}
          value={flight.toLocation}
          onChange={(event, newValue) => handleFlightChange(index, 'toLocation', newValue)}
          isOptionEqualToValue={(option, value) => option.code === value.code}
        />
      </Grid>
      <Grid item sx={{ width: {xs: '100%', sm: "40%", md: gridWidth } }}>
        <TextField
          fullWidth
          label="Departure Date"
          type="date"
          value={flight.departureDate}
          onChange={(e) => handleFlightChange(index, 'departureDate', e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      {tripType !== "Multi City" && (
      <Grid item sx={{ width: {xs: '100%', sm: "40%", md: flightSearchPage ? "16%" : "22%" } }}>
        <TextField
          fullWidth
          label="Returning"
          type="date"
          value={returnDate}
          onChange={(e) => setReturnDate(e.target.value)}
          size="small"
          disabled={tripType !== "Return"}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      )}
            {!isMobile && index < 5 && tripType === "Multi City" && (
        <Grid item sx={{ width: { sm: "5%", md: flightSearchPage ? "2%" : "4%" } }}>
          {index >= 2 && (
            <IconButton onClick={() => handleRemoveFlight(index)}>
              <Close />
            </IconButton>
          )}
        </Grid>
      )}
      </>
  );

  const renderFlightSearchBox = () => (
    <>
      <Typography
        variant={flightSearchPage ? "" : "h4"}
        color="white"
        fontWeight="bold"
        sx={{ mb: flightSearchPage ? 0 : 4, textAlign: "center", fontSize: { xs: "h6", sm: "h4" } }}
      >
        Travel Bookings Made Easy
      </Typography>
      <Paper
        elevation={flightSearchPage ? 0 : 4}
        sx={{
          p: { xs: 2, md: flightSearchPage ? 0 : 4 },
          width: "100%",
          maxWidth: { xs: "400px", md: flightSearchPage ? "1400px" : "1200px" },
          boxSizing: flightSearchPage ? "content-box" : "border-box",
          borderRadius: flightSearchPage ? 0 : 2,
        }}
      >
        <Grid container spacing={2}>
          {/* Trip Type Buttons */}
          <Grid item xs={12} container spacing={1}>
            {["One Way", "Return", "Multi City"].map((option) => (
              <Grid item xs={4} sm={3} md={4} key={option}>
                <Button
                  variant="outlined"
                  onClick={() => setTripType(option)}
                  fullWidth
                  sx={{
                    backgroundColor: tripType === option ? '#fafafa' : 'transparent',
                    color: tripType === option ? '#007BFF' : 'black',
                    borderColor: '#9dbbd7',
                    borderRadius: '24px',
                    paddingX: 2,
                    paddingY: 1,
                    
                  }}
          
                >
                  {option}
                </Button>
              </Grid>
              
            ))}
          

          <Grid item>
            <Button
              className="text-blue-600 flex gap-2"
              onClick={() => setIsTravelersModalOpen(true)}
            >
              {travelerText}
              <Person />
              <ArrowDropDown />
            </Button>
          </Grid>
          <Grid item>
            <ClassSelector
              selectedClass={classType}
              onClassChange={setClassType}
            />
          </Grid>
        </Grid>

        {/* Locations */}
        <Grid
          item
          xs={12}
          sx={{ width: { sm: "100%", md: "100%" } }}
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {tripType === "Multi City" ? (
            multiCityFlights.map((flight, index) => renderFlightFields(flight, index))
          ) : (
            renderFlightFields(multiCityFlights[0], 0)
          )}
                {tripType === "Multi City" && multiCityFlights.length < 5 && (
      <Grid
            item
            xs={12}
            className="align-center justify-center flex cursor-pointer"
            onClick={() => {
              if (multiCityFlights.length < 5) {
                setMultiCityFlights([...multiCityFlights, { fromLocation: null, toLocation: null, departureDate: "" }]);
              }
            }}
          >
              + Add Destination
          </Grid>
      )}

          <Grid
            item
            xs={12}
            className={`align-center justify-center flex content-center ${
              flightSearchPage ? "" : "w-full"
            }`}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#0a549c",
                color: "#FFFFFF",
              }}
              startIcon={<Search />}
              onClick={handleSearch}
              className="border-radius-[4px] w-[271px]  h-12"
            >
              Search Flights
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
    { isTravelersModalOpen && (
      <Dialog
        open={isTravelersModalOpen}
        onClose={() => setIsTravelersModalOpen(false)}
        aria-labelledby="travelers-dialog-title"
      >
          <TravelersSelection
            onClose={() => setIsTravelersModalOpen(false)}
            onTravelersUpdate={handleTravelersUpdate}
            initialTravelers={{ adults, children, infants }}
          />
      </Dialog>

    )}
    </>
  );

return (
  <Box
    sx={{
      backgroundColor: flightSearchPage ? "transparent" : "#1E3A8A",
      minHeight: flightSearchPage ? "auto" : "404px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      backgroundImage: flightSearchPage ? "none" : "url('/filter-bg.png')",
      backgroundRepeat: flightSearchPage ? "none" : "repeat",
      px: 2,
    }}
  >
    {renderFlightSearchBox()}
  </Box>
);
};

FlightSearchResults.propTypes = {
  flightSearchPage: PropTypes.bool,
};

export default FlightSearchResults;
