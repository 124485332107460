import React from "react";
import FlightCard from "./FlightCard";

const FlightList = ({ flights }) => (
  <div className="mt-8">
    <h2 className="text-xl text-blue-600">
      Select your departing flight to Lahore
    </h2>
    <p className="text-gray-600">Total one way price for all travelers</p>
    {flights.map((flight) => (
      <FlightCard key={flight.id} flight={flight} />
    ))}
  </div>
);

export default FlightList;
