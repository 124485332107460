import React, { useState } from "react";
import Filters from "./Filters";
import FlightList from "./FlightList";
import CustomerSupport from "./CustomerSupport";
import FlightSearchResults from "../Home/FlightSearchResults";

const mockFlights = [
  {
    id: 1,
    airline: "Airblue",
    flightNumber: "PA-001",
    departure: "10:00 AM",
    arrival: "12:05 PM",
    duration: "2h 5m",
    price: 71,
    meal: true,
  },
  {
    id: 2,
    airline: "Airblue",
    flightNumber: "PA-007",
    departure: "05:00 PM",
    arrival: "07:05 PM",
    duration: "2h 5m",
    price: 64,
    meal: true,
  },
  {
    id: 3,
    airline: "Fly Jinnah",
    flightNumber: "FJ-091",
    departure: "09:35 AM",
    arrival: "11:30 AM",
    duration: "1h 55m",
    price: 70,
    meal: false,
  },
  {
    id: 4,
    airline: "PIA",
    flightNumber: "PK-300",
    departure: "02:00 PM",
    arrival: "03:55 PM",
    duration: "1h 55m",
    price: 69,
    meal: true,
  },
  {
    id: 5,
    airline: "Airsial",
    flightNumber: "PF-124",
    departure: "04:00 PM",
    arrival: "06:00 PM",
    duration: "2h",
    price: 72,
    meal: true,
  },
];

const FlightSearch = () => {
  const [flights] = useState(mockFlights);

  return (
    <div>
      <div className="bg-white p-4 md:px-28 rounded shadow min-h-[112px]">
        {/* <TripSelector />
        <SearchBar /> */}
        <FlightSearchResults flightSearchPage={true} />
      </div>
      <div className="flex flex-col md:px-24 md:flex-row bg-[#f7f8fb]">
        <div className="mt-6 p-4 bg-[#f7f8fb] md:flex-1">
          <Filters />
          <FlightList flights={flights} />
        </div>
        <CustomerSupport />
      </div>
    </div>
  );
};

export default FlightSearch;
