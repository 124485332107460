import React, { useState } from 'react';
// import PaymentMethod from './components/PaymentMethod';
import ProgressBar from './components/ProgressBar';
import { Container, Box } from '@mui/material';
import BookingForm from './components/Booking';
import PaymentMethod from './components/Payment';

function ManageBooking() {
  const [step, setStep] = useState(1); // 1: Booking, 2: Payment, 3: E-ticket

  return (
    <div className='bg-[#f7f8fb]'>
    <Container sx={{backgroundColor:' #f7f8fb' ,paddingTop: 2}}>
      <ProgressBar step={step} setStep={setStep} />
      <Box sx={{}}>
        {step === 1 && <BookingForm onNext={() => setStep(1)} />}
        {step === 2 && <PaymentMethod onNext={() => setStep(2)} />}
        {step === 3 && <Box sx={{ textAlign: 'center', mt: 5 }}>Thank you! Your E-ticket will be sent via email.</Box>}
      </Box>
    </Container>
    </div>
  );
}
export default ManageBooking;
