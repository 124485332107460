import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleNavigation } from '../../Utils/utils-functions';
import useIsMobile from '../../reusablecomponents/useIsMobile';
import PhoneNumberInput from '../../reusablecomponents/phoneNumberInput';

const SignInDialog = (props) => {
  const { open, setOpen } = props;
  const [mobileNumber, setMobileNumber] = useState('');
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          position: 'fixed',
          bottom: isMobile ? 0 : 'auto',
          margin: 0,
          borderRadius: isMobile ? '8px 8px 0 0' : undefined,
          width: isMobile ? '100%' : undefined,
        },
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px' }}>
        <DialogTitle>Continue to your account</DialogTitle>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent dividers>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img
            src="/tourist_explore.svg" // Replace with your illustration image
            alt="Travel Illustration"
            style={{ width: '100%' }}
          />
        </div>

        {/* Combined Country Code + Mobile Number TextField */}
        <PhoneNumberInput
        label="Enter your phone number"
        defaultCountryCode="+92"
        onClose={handleClose}
        open={open}
        mobileNumber={mobileNumber} 
        setMobileNumber={setMobileNumber}
      />

        <Button
          variant="contained"
          fullWidth
          style={{ marginTop: '16px',
            backgroundColor: mobileNumber.length >= 10 ? '#256ba8' : '#E1E4EB', // Blue when valid
            color: mobileNumber.length >= 10 ? '#FFFFFF' : ''
          }} 
          disabled={mobileNumber.length < 10}  // Adjust length for validation
          onClick={() => handleNavigation("/user-profile")}
        >
          Continue
        </Button>

        <div style={{ marginTop: '8px', textAlign: 'center', fontSize: '12px' }}>
          By continuing you are agreeing to the{' '}
          <a href="/terms-and-conditions" style={{ textDecoration: 'none', color: '#1976d2' }}>
            Terms and Conditions
          </a>{' '}
          of Ticketbazaar.pk.
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignInDialog;