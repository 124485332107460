import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

// Sample items array for the images and labels
const items = [
  {
    imgSrc: '/images/customer-support-24-7.7576599.svg',
    altText: 'Customer Support',
    label: '24/7 Customer Support',
  },
  {
    imgSrc: '/images/refunds-within-48-hours.ab68578.svg',
    altText: 'Refunds',
    label: 'Refunds within 48 hours',
  },
  {
    imgSrc: '/images/secure-transaction-guaranteed.5d41198.svg',
    altText: 'Secure Transactions',
    label: 'Secure Transaction Guaranteed',
  },
];


const ScrollableImageGrid = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box component="section" bgcolor="#e7eef5" py={6} overflow="hidden">
      <Container maxWidth="lg">
        <Box
          display="flex"
          overflowX={{ xs: 'auto', md: 'unset' }} // Enables horizontal scroll on small screens, disables on larger ones
          className="scrolling-container"
          px={2}
          sx={{
            scrollbarWidth: 'none',  // For Firefox
            '&::-webkit-scrollbar': { display: 'none' }, // For Chrome, Safari
          }}
        >
          <Grid container spacing={2} justifyContent={{ xs: 'flex-start', md: 'space-between' }}>
            {items.map((item, index) => (
              <Grid
                item
                key={index}
                xs={12}  // Takes full width on small screens
                sm={6}  // Takes 50% width on tablets
                md={4}  // Takes 1/3 width on larger screens
                lg={3}  // Takes 1/4 width on desktops
                display={{ xs: index === currentIndex ? 'flex' : 'none', md: 'flex' }}
                flexDirection="column"
                alignItems="center"
              >
                <Box
                  component="img"
                  src={item.imgSrc}
                  alt={item.altText}
                  height={172}
                  sx={{ objectFit: 'cover' }}
                />
                <Typography variant="h6" fontWeight="bold" mt={2} textAlign="center">
                  {item.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ScrollableImageGrid;
