import React, { useState } from "react";
import MoreFiltersDropdown from "./MoreFiltersDropdown";

const Filters = () => {
  const [activeDate, setActiveDate] = useState("Fri, 17 Jan");
  const [activeFilter, setActiveFilter] = useState("Cheapest");

  const dates = [
    { day: "Tue, 14 Jan", price: "PKR 58,581" },
    { day: "Wed, 15 Jan", price: "PKR 58,473" },
    { day: "Thu, 16 Jan", price: "PKR 58,581" },
    { day: "Fri, 17 Jan", price: "PKR 58,473" },
    { day: "Sat, 19 Jan", price: "PKR 58,581" },
    { day: "Sat, 20 Jan", price: "PKR 58,581" },
    { day: "Sat, 21 Jan", price: "PKR 58,581" },
  ];

  const filters = ["Suggested", "Cheapest", "Fastest"];

  return (
    <div className="w-full">
  {/* Date Picker Section */}
  <div className="flex items-center space-x-4 overflow-x-auto no-scrollbar whitespace-nowrap">
    {dates.map((date, index) => (
      <div
        key={index}
        onClick={() => setActiveDate(date.day)}
        className={`p-4 min-w-[100px] sm:min-w-[120px] inline-block text-center rounded-lg cursor-pointer ${activeDate === date.day
          ? "bg-blue-100 border border-blue-400"
          : "bg-white"
          }`}
      >
        <p className="font-semibold text-sm sm:text-base">{date.day}</p>
        <p className="text-xs sm:text-sm text-gray-500">{date.price}</p>
      </div>
    ))}
  </div>

  {/* Filters Section */}
  <div className="mt-4">
    <div className="flex justify-between items-center border-b bg-white text-[#0a549c] px-2 sm:px-4 mb-4">
      {/* Filter Tabs */}
      <div className="flex space-x-2 sm:space-x-4 overflow-x-auto no-scrollbar">
        {filters.map((filter, idx) => (
          <button
            key={idx}
            onClick={() => setActiveFilter(filter)}
            className={`py-2 sm:py-4 px-2 sm:px-4 text-xs sm:text-sm transition-colors whitespace-nowrap rounded-md ${activeFilter === filter
              ? "bg-[#e7eef5] text-[#0a549c]"
              : "border-transparent hover:border-blue-400"
              }`}
          >
            {filter}
          </button>
        ))}
      </div>

      {/* Filter Options Aligned to Right */}
      <div className="flex items-center space-x-2 sm:space-x-4">
        <button className="hidden sm:inline-block border px-3 py-1 rounded-full text-sm hover:bg-gray-100">
          Nonstop
        </button>
        <button className="hidden sm:inline-block border px-3 py-1 rounded-full text-sm hover:bg-gray-100">
          1 Stop
        </button>
        {/* More Filters Button */}
        <MoreFiltersDropdown />
      </div>
    </div>
  </div>
</div>

  );
};

export default Filters;

