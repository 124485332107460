import React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Box,
  styled,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";

// Custom Connector Style
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  "&.MuiStepConnector-root": {
    top: 10,
  },
  "& .MuiStepConnector-line": {
    borderTopWidth: 2,
    borderColor: "#e0e0e0",
  },
}));

// Custom Step Icon
const CustomStepIcon = styled("div")(({ theme, ownerState }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: ownerState.active ? "#1976d2" : "#e0e0e0",
  color: ownerState.active ? "#fff" : "#757575",
  border: `2px solid ${ownerState.completed ? "#1976d2" : "#e0e0e0"}`,
}));

const StepIconComponent = (props) => {
  const { active, completed, icon } = props;

  return (
    <CustomStepIcon ownerState={{ active, completed }}>
      {completed ? <CheckIcon /> : icon === 1 ? <FlightTakeoffIcon /> : icon}
    </CustomStepIcon>
  );
};

const ProgressBar = ({ step, setStep }) => {
  const steps = ["Booking", "Payment", "E-ticket"];

  return (
    <Box sx={{ width: "100%", mt: 2, mb: 4 ,backgroundColor:'white' }}>
      <Stepper
        activeStep={step - 1}
        alternativeLabel
        connector={<CustomConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label} onClick={() => setStep(index + 1)}>
            <StepLabel
              StepIconComponent={(props) => (
                <StepIconComponent {...props} icon={index + 1} />
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ProgressBar;

