import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#f7f8fb] min-h-screen py-10">
       <h1 className="text-3xl  text-[#08437d] pl-8 pb-4 mb-6">
          Privacy Policy
        </h1>
      <div className="max-w-7xl mx-auto">
       

        <div className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
          <h2 className="text-2xl  text-[#0a549c]  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4">
            Privacy Policy
          </h2>
          <p className="font-mulish px-4 leading-7 mb-4">
            Our Privacy Policy has been created to transparently outline how we
            handle your personal information. It is important for us to ensure
            you have utmost confidence and trust in Ticketbazaar. Please read
            our policy carefully.
          </p>
          <p className="font-mulish px-4 leading-7 mb-4">
            We understand that privacy and security are of paramount importance
            to our users. Therefore, we have dedicated time and resources to
            ensure that we follow international best practices to protect our
            users.
          </p>
          <p className="font-mulish px-4 leading-7 mb-4">
            Please keep in mind that our Privacy Policy is limited to our
            website and mobile app and in no way covers websites and mobile
            apps that are owned or operated by third parties.
          </p>
          <p className="font-mulish px-4 leading-7 mb-4">
            At any given time the user can delete his/her account with
            Ticketbazaar from their profile section on the web and app both.
          </p>
        </div>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300  text-[#0a549c] mb-4">
            Type of Information We Collect
          </h2>
          <p className="font-mulish px-4  mb-2">
            What is Personal Information:
          </p>
          <ul className="font-mulish px-4 list-disc list-inside mb-4">
            <li>Full name</li>
            <li>Email address</li>
            <li>Mailing address</li>
            <li>Phone number</li>
            <li>Credit card number</li>
            <li>Other information you submit voluntarily</li>
          </ul>
          <p className="font-mulish px-4 leading-7">
            If you do not want us to get access to your Personal Information,
            please do not voluntarily share this information with us through
            our website or call center.
          </p>
        </section>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300  text-[#0a549c] mb-4">
            Use of Personal Information
          </h2>
          <p className="font-mulish px-4 leading-7 mb-4">
            Ticketbazaar considers privacy and confidentiality of your Personal Information very seriously. We do not share your Personal Information without your consent. Unless you give us permission, Ticketbazaar will not sell, share, license, or rent your Personal Information other than as specified in this Privacy Policy.
          </p>
          <p className="font-mulish px-4 leading-7 mb-4">
            Please note that if you choose to withhold Personal Information, you will not be able to access certain parts of our website
          </p>
          <p className="font-mulish px-4 leading-7 mb-4">
            Email and other forms of Communication – You may also choose to communicate with Ticketbazaar through email, live chat, in writing. We will use your Personal Information communicated over these means in accordance with our Privacy Policy.
          </p>
        </section>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300  text-[#0a549c] mb-4">
            Automatic Collection of Non-Personal Information
          </h2>
          <p className="font-mulish  px-4 mb-2">
            Some of our web pages use “cookies” so that we can give you a better customer experience through customization based on your recent website visit and searches. You can choose to accept or not accept cookies through your browser settings.
          </p>

          <p className="font-mulish px-4  mb-2">
            Non-personal information that is collected on our website includes
          </p>

          <ul className="font-mulish px-4 list-disc list-inside mb-4">
            <li>Type of browser</li>
            <li>IP Address</li>
            <li>Date and time of website access</li>
            <li>Pages that you have accessed on our website</li>
            <li>Internet address from which you have linked to our website</li>

          </ul>
          <p className="font-mulish px-4 leading-7">
            Non-personal information that is collected by us is used to help us better understand our customers. This data helps us improve our customer experience.
          </p>
        </section>

        <section className="mb-6 bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4 text-[#0a549c] ">Information We Use</h2>
          <p className="font-mulish  px-4  mb-4">
            Personal information and data that is collected by Ticketbazaar is used for the
            following purposes:
          </p>
          <ul className="list-disc  px-4 font-mulish list-inside  mb-4">
            <li>Providing services and support to users</li>
            <li>Improving website</li>
            <li>Responding to user questions</li>
            <li>Maintaining website security</li>
          </ul>
          <p className="font-mulish px-4 ">
            If users give consent, personal information is transferred to the following third
            parties:
          </p>
          <ul className="list-disc px-4 list-inside font-mulish mt-2">
            <li>Banks</li>
            <li>Travel Suppliers such as:</li>
            <ul className="list-circle px-4 list-inside ml-4 font-mulish">
              <li>Airlines</li>
              <li>Tour Operators</li>
            </ul>
          </ul>
        </section>

        <section className="mb-6 bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4 text-[#0a549c] ">
            Privacy Policy and Ticketbazaar Technology
          </h2>
          <p className="font-mulish  px-4  mb-4">
            By registering on Ticketbazaar, our members benefit from not having to enter personal
            information and preferences resulting in faster check-out. When registering as a
            member, you are able to choose which information you would like to provide us. As a
            member you are able to enter and view the following information:
          </p>
          <ul className="list-disc px-4  list-inside font-mulish">
            <li>Edit My Details</li>
            <li>View past searches</li>
            <li>View past quotes</li>
            <li>View bookings</li>
          </ul>
          <p className="font-mulish  px-4 mt-4">
            We reserve the right to send you services and administrative communications. We also
            maintain the right to send you communication regarding transactions on our website.
          </p>
        </section>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
          <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4 text-[#0a549c] mb-2">Email Communications</h2>
          <p className="font-mulish px-4">
            We want to ensure that our communication adds value to our customers through a great
            customer experience and by sharing information that we believe may be of interest to
            you. We believe email communication is vital and therefore we will be sending you
            regular emails on special offers, promotions, discounts, recent searches, quotes,
            booking details, etc. If you do not wish to receive emails from Ticketbazaar, you have
            the option of unsubscribing through a link at the bottom of our email messages.
          </p>
        </section>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
        <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4 text-[#0a549c] ">Changes to Ticketbazaar Privacy Policy</h2>
          <p className="font-mulish px-4">
          Any change in our Privacy Policy will be updated on our website. We reserve the right to modify our Policy Statement at any time and without any prior notice, so please review our website regularly. By using our website, you agree to our Privacy Policy and Terms and Conditions.
          </p>
        </section>

        <section className="bg-white shadow rounded border border-solid border-[#d9d9d9] mb-6">
        <h2 className="text-2xl  bg-[#f5f5f5] px-4 pb-2 rounded-t border-b border-gray-300 mb-4 text-[#0a549c] ">Smartlook</h2>
          <p className="font-mulish px-4">
          We may use Smartlook, which is an analytics solution. Smartlook may record: Screens visited, Interaction patterns (such as screen actions, gestures: taps, scrolls), and Device details (Type, Version, Model, Operating System). We are using the information collected by Smartlook to improve our app.
          </p>
          <p className="font-mulish px-4">
          Smartlook does not collect personally identifiable information and does not track your browsing habits across apps. For more information see Privacy Policy for Information Collected by the Smartlook Service.
          </p>
        </section>


      </div>
    </div>
  );
};

export default PrivacyPolicy;

