export default function PurchaseHistory() {
    // Component logic goes here

    return (
                <div className="text-center h-96">
                    <img src="images/purchase-no-history.svg" alt="Illustration of a person hiking in nature" className="mx-auto mb-6" />
                    <h1 className="text-2xl font-medium text-gray-800 mb-2">You don’t have any upcoming trips</h1>
                    <p className="text-gray-600 font-mulish mb-6">Where are you going next?</p>
                    <button className="bg-[#0a549c] border border-[#0a549c] text-white py-2 px-4 rounded">Explore Ticketbazaar.pk</button>
                </div>
    );
}