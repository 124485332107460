import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { handleNavigation } from "../../Utils/utils-functions";

const FareOption = ({ title, price, details, refundPrice }) => {
  return (
    <div className="border rounded-lg p-4">
      <div className="flex justify-between items-center mb-2">
        <div className="text-lg font-bold">{title}</div>
        <div className="text-lg font-bold">${price}</div>
      </div>
      {details.cheapest && <div className="text-sm text-green-500 mb-2">Cheapest</div>}
      <ul className="text-sm text-gray-700 mb-4">
        {details.features.map((feature, index) => (
          <li key={index}>
            <i className={`fas ${feature.icon}`}></i> {feature.description}
          </li>
        ))}
      </ul>
      <div className="border rounded-lg p-2 mb-4">
        <div className="flex justify-between items-center mb-2">
          <div className="text-sm font-bold">
            Sasta Refund <i className="fas fa-info-circle"></i>
          </div>
          <div className="text-sm font-bold">${refundPrice}</div>
        </div>
        <div className="text-sm text-gray-500 mb-2">
          Modify or cancel your trip for free before departure.
        </div>
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" />
          <label className="text-sm">Add to this flight</label>
        </div>
      </div>
      <button className="w-full bg-blue-500 text-white py-2 rounded" onClick={() => {handleNavigation('/manage-booking')}}>Select</button>
    </div>
  );
};

const FlightCard = () => {
  const [showFareOptions, setShowFareOptions] = useState(false);

  const handleToggleFareOptions = () => {
    setShowFareOptions(!showFareOptions);
  };

  const fareOptions = [
    {
      title: "Value",
      price: 349,
      details: {
        cheapest: true,
        features: [
          {
            icon: "fa-suitcase",
            description: "Check-in Baggage: Additional Cost",
          },
          {
            icon: "fa-times-circle",
            description: "Cancellation: Penalties Apply",
          },
          {
            icon: "fa-edit",
            description: "Modification: Penalties Apply",
          },
          {
            icon: "fa-chair",
            description: "Seat: Not Included",
          },
          {
            icon: "fa-utensils",
            description: "Meal: Included",
          },
        ],
      },
      refundPrice: 31,
    },
    {
      title: "Flexi",
      price: 360,
      details: {
        features: [
          {
            icon: "fa-suitcase",
            description: "Check-in Baggage: Total: 20Kgs PCs: 1",
          },
          {
            icon: "fa-times-circle",
            description: "Cancellation: Penalties Apply",
          },
          {
            icon: "fa-edit",
            description: "Modification: Penalties Apply",
          },
          {
            icon: "fa-chair",
            description: "Seat: Not Included",
          },
          {
            icon: "fa-utensils",
            description: "Meal: Included",
          },
        ],
      },
      refundPrice: 31,
    },
    {
      title: "Xtra",
      price: 379,
      details: {
        features: [
          {
            icon: "fa-suitcase",
            description: "Check-in Baggage: Total: 30Kgs PCs: 1",
          },
          {
            icon: "fa-times-circle",
            description: "Cancellation: Penalties Apply",
          },
          {
            icon: "fa-edit",
            description: "Modification: Penalties Apply",
          },
          {
            icon: "fa-chair",
            description: "Seat: Not Included",
          },
          {
            icon: "fa-utensils",
            description: "Meal: Included",
          },
        ],
      },
      refundPrice: 31,
    },
  ];

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg mt-10">
      <div className={`flex justify-between items-center ${showFareOptions ? 'border-b pb-4 mb-4' : ''}`}>
        <div className="flex items-center flex-col">
          <img
            src="https://s3.ap-south-1.amazonaws.com/st-airline-images/PA.png"
            alt="Airline Logo"
            className="h-[40px] w-[40px] mr-2"
          />
          <div className="text-sm text-gray-500">Airblue</div>
          <div className="text-sm text-gray-500">PA-170 (value)</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-bold">02:00 AM</div>
          <div className="text-sm text-gray-500">Karachi (KHI)</div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-500">5h</div>
          <div className="text-sm text-gray-500">Nonstop</div>
        </div>
        <div className="text-center">
          <div className="text-2xl font-bold">05:00 AM</div>
          <div className="text-sm text-gray-500">Jeddah (JED)</div>
        </div>
        <div className="text-center">
          <div className="text-sm text-gray-500">
            <i className="fas fa-utensils"></i> Meal
          </div>
        </div>
        <button
          className="text-blue-500 border border-blue-500 rounded px-4 py-2"
          onClick={handleToggleFareOptions}
        >
          {showFareOptions ? "Hide" : "$359"}
        </button>
      </div>
      {showFareOptions && (
        <div>
          <h2 className="text-lg font-bold mb-4">Select a fare option</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {fareOptions.map((option, index) => (
              <FareOption key={index} {...option} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FlightCard;
