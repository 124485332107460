import React from 'react'



const SastaRefund = () => {
  return (
    <div className=" bg-[#f7f8fb] ">
      <div className="container mx-auto px-4 py-10 mb-6">
        <h1 className="text-2xl  text-[#08437d]  pl-3 mb-4" >Sasta Refund FAQs </h1>
        <div className="bg-white shadow-md p-6 rounded-lg">
          <h2 className="text-xl  text-[#0A549C] mb-4">Terms and Conditions: Ticketbazaar Sasta Refund</h2 >
          <p className="text-[#262626]  mb-4 font-mulish">Ticketbazaar’s Sasta Refund (the “Service”) enables you to make qualified changes to a purchased Ticketbazaar booking at any time, for any reason, up to a minute (1) prior to the departure of the first or outgoing leg (“Cancel Deadline”) of your itinerary, without any airline cancellation fee. The Service is subject to the following requirements and restrictions:</p>
          <ol className="list-decimal text-[#262626] list-inside ml-6 space-y-4 font-mulish">
            <li >Purchasing the Service: The Service must be purchased at the time of booking, and may not be added after the booking. The Service must be purchased for all passengers on the booking. Ticketbazaar may not offer the Service on every Ticketbazaar booking. Ticketbazaar reserves the right to deny the purchase of the Service to any customer for any lawful reason.</li>
            <li>When to Make Cancellations: Except as provided below in Section 6, you must cancel your trip with Ticketbazaar at least a minute (1) prior to the departure of the first or outgoing leg (“Cancel Deadline”) of your itinerary. No reason will be required to make a qualified cancellation. After the Cancel Deadline has passed, the purchased Service will expire and any cancellations to your booking thereafter will be subject to the fare rules imposed by the airline or carrier for your dates.In the case of a ‘RETURN’ booking, you can only cancel the complete or partial trip ONLY once. It is also important to note, you can either modify or cancel once for free, per booking.</li>
            <li>The price paid to add or avail the Sasta Refund feature/service is non-refundable under any and all circumstances except in the case of involuntary cancellation. In all other cases, 100% refund guarantee applies to the price paid for the flight ticket(s) only.</li>
            <li>Exclusions: The Service does not apply on the following:
              <ol className=" list-inside ml-6 font-mulish">
                <li>
                  a. a declaration of an “emergency” or similar “extraordinary measures” taken by any governmental authority in the destination city, region, or country of your Ticketbazaar trip, or issuance of a Safety and Security Alert by the Ministry of Interior or a Travel Health Notice Warning by the Pakistan Center for Disease Control (CDC) for such destination; or
                  In the event of such cancellation, or declaration of emergency, alert, notice, designation, or other similar government action, your purchased Service shall expire immediately and cancellation of your trip and/or refund of your Ticketbazaar trip purchase will be subject to the fare rules imposed by the airline carrier for your ticket.</li>
              </ol>
            </li>
            <li>Other Restrictions: The Service is purchased per flight booking and provides the Service for all passengers included on the travel itinerary at the time of booking. If there is more than one passenger on the travel itinerary, the passenger who made the booking shall determine how to exercise the Service, which shall apply to any other passenger included on the travel itinerary. The Service is only valid for the booking for which the Service was purchased. The Service is not transferable and cannot be carried over to any future bookings. The Service cost is non-refundable except in the case of involuntary cancellation.</li>
            <li>Not Insurance: The Service ensures that airline tickets purchased on the Ticketbazaar platforms can be canceled for any reason without incurring additional fees. Ticketbazaar provides its customers with the Service to provide a more seamless and flexible experience for travel booked on the Ticketbazaar platforms. The Service Terms are not intended to constitute an offer to insure, do not constitute insurance or an insurance contract, and do not take the place of insurance obtained or obtainable by you.</li>
          <li>
          Additional Information: For detailed information regarding your existing change policy from the respective airline carrier, visit the airline’s website.
          </li>
          </ol>
        </div>
      </div>

    </div>
  )
}

export default SastaRefund
