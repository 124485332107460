import React from "react";
import { Container, Typography} from "@mui/material";
import BranchCard from "../../reusablecomponents/BranchCard"; 
import Grid from '@mui/material/Grid2';

const branches = [
  {
    name: "Ticketbazaar.pk - Corporate Office",
    address: "Plot 330-D, Alamgir Rd, Khokhan Society Bahadur Yar Jang CHS, Karachi, Karachi City, Sindh 74800",
    uan: "(021) 111 172 782",
    whatsapp: "923047772782",
    id: '1',
    workingHours: "08:00 AM to 11:59 PM (Monday to Sunday)",
    mapUrl: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319", // Embed URL
  },
  {
    name: "Ticketbazaar.pk - Corporate Office",
    address: "Plot 330-D, Alamgir Rd, Khokhan Society Bahadur Yar Jang CHS, Karachi, Karachi City, Sindh 74800",
    uan: "(021) 111 172 782",
    whatsapp: "923047772782",
    id: '2',
    workingHours: "08:00 AM to 11:59 PM (Monday to Sunday)",
    mapUrl: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319", // Embed URL
  },
  {
    name: "Ticketbazaar.pk - Corporate Office",
    address: "Plot 330-D, Alamgir Rd, Khokhan Society Bahadur Yar Jang CHS, Karachi, Karachi City, Sindh 74800",
    uan: "(021) 111 172 782",
    whatsapp: "923047772782",
    id: '3',
    workingHours: "08:00 AM to 11:59 PM (Monday to Sunday)",
    mapUrl: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319", // Embed URL
  },
  {
    name: "Ticketbazaar.pk - Corporate Office",
    address: "Plot 330-D, Alamgir Rd, Khokhan Society Bahadur Yar Jang CHS, Karachi, Karachi City, Sindh 74800",
    uan: "(021) 111 172 782",
    whatsapp: "923047772782",
    id: '4',
    workingHours: "08:00 AM to 11:59 PM (Monday to Sunday)",
    mapUrl: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319", // Embed URL
  },
];

const ContactUs = () => {
  return (
    <div className=" bg-[#f7f8fb]">
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h5" sx={{ color:"#08437d" }} gutterBottom>
        Contact Us
      </Typography>
      <Grid container spacing={4}>
        {branches.map((branch, index) => (
          <Grid item xs={12} key={index}>
            <BranchCard branch={branch} />
          </Grid>
        ))}
      </Grid>
    </Container>
    </div>
  );
};

export default ContactUs;
