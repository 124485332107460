/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

export default function EditProfile() {
    useEffect(() => {
        // Populate Days
        const daysSelect = document.getElementById("days");
        for (let i = 1; i <= 31; i++) {
            const option = document.createElement("option");
            option.value = i;
            option.textContent = i;
            daysSelect.appendChild(option);
        }
    
        // Populate Months
        const monthsSelect = document.getElementById("months");
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        months.forEach((month, index) => {
            const option = document.createElement("option");
            option.value = index + 1; // Month index (1-12)
            option.textContent = month;
            monthsSelect.appendChild(option);
        });
    
        // Populate Years
        const yearsSelect = document.getElementById("years");
        const currentYear = new Date().getFullYear();
        for (let i = 1970; i <= currentYear; i++) {
            const option = document.createElement("option");
            option.value = i;
            option.textContent = i;
            yearsSelect.appendChild(option);
        }
    
        // Populate Countries
        const countries = [
            "Pakistan", "United States", "United Kingdom", "India",
            "Canada", "Australia", "Germany", "France", "China", "Japan"
        ];
        const countrySelect = document.getElementById("country");
        countries.forEach(country => {
            const option = document.createElement("option");
            option.value = country;
            option.textContent = country;
            countrySelect.appendChild(option);
        });
    }, []);
    


    return (
        <main className="flex-1 bg-white pt-2 md:p-8 shadow-md">
            <h2 className="text-lg md:text-xl font-reem mb-4 md:mb-6">Your Account</h2>
            <form className="space-y-4 md:space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                    {/* Title Dropdown */}
                    <div>
                        <label className="block text-sm font-medium text-[#262626]">Title</label>
                        <select className="mt-1 block w-full border border-red-500 rounded py-2 px-3 text-[#262626]">
                            <option value="mr">Mr.</option>
                            <option value="ms">Ms.</option>
                            <option value="dr">Mrs.</option>
                        </select>
                        <p className="text-red-500 text-sm mt-1">Please select your title</p>
                    </div>

                    {/* Name Fields */}
                    <div>
                        <label className="block text-sm font-medium text-[#262626]">First & Middle Name</label>
                        <input
                            type="text"
                            className="mt-1 block w-full border border-red-500 rounded py-2 px-3 text-[#262626]"
                            placeholder="Enter your first and middle name"
                        />
                        <p className="text-red-500 text-sm mt-1">Please enter your first name</p>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-[#262626]">Last Name</label>
                        <input
                            type="text"
                            className="mt-1 block w-full border border-red-500 rounded py-2 px-3 text-[#262626]"
                            placeholder="Enter your last name"
                        />
                        <p className="text-red-500 text-sm mt-1">Please enter your last name</p>
                    </div>
                    
                    {/* Email */}
                    <div>
                        <label className="block text-sm font-medium text-[#262626]">Email</label>
                        <input
                            type="email"
                            className="mt-1 block w-full border border-red-500 rounded py-2 px-3 text-[#262626]"
                            placeholder="Enter your email address"
                        />
                        <p className="text-red-500 text-sm mt-1">Please input your email</p>
                    </div>

                    {/* Mobile Number */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
                    <div>
                        <label className="block text-sm font-medium text-[#262626]">Mobile Number</label>
                        <div className="flex mt-1">
                            <span className="inline-flex items-center px-3 border border-gray-300 bg-gray-50 text-gray-500 text-sm rounded-l-md">
                                +92
                            </span>
                            <input
                                type="text"
                                className="flex-1 block w-full border border-gray-300 rounded-r-md py-2 px-3 text-[#262626]"
                                placeholder="3155121229"
                            />
                        </div>
                        <p className="text-gray-500 text-sm mt-1">e.g 03320234557</p>
                    </div>
                </div>

               
                {/* Date of Birth Fields */}
                <div>
                        <label className="block text-sm font-medium text-[#262626]">Date of Birth</label>
                        <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-1">
                            <select id="days" className="block w-full sm:w-1/3 border border-gray-300 rounded py-2 px-3 text-[#262626]">
                                <option disabled selected>Day</option>
                            </select>
                            <select id="months" className="block w-full sm:w-1/3 border border-gray-300 rounded py-2 px-3 text-[#262626]">
                                <option disabled selected>Month</option>
                            </select>
                            <select id="years" className="block w-full sm:w-1/3 border border-gray-300 rounded py-2 px-3 text-[#262626]">
                                <option disabled selected>Year</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-[#262626]">Country</label>
                        <select
                            id="country"
                            className="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-[#262626]"
                        >
                            <option disabled selected>Select a country</option>
                        </select>
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-[#262626]">State</label>
                        <input type="text" class="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-[#262626]" />
                    </div>
                    <div>
                        <label class="block text-sm font-medium text-[#262626]">City</label>
                        <input type="text" class="mt-1 block w-full border border-gray-300 rounded py-2 px-3 text-[#262626]" />
                    </div>
                

                {/* Save Button and Delete my account */}
                <div className="flex flex-col sm:flex-row justify-evenly items-center space-y-4 sm:space-y-0">
                    <button
                        type="button"
                        className="py-2 px-4 bg-[#0a549c] text-[#fff] border border-[#0a549c] w-full rounded  sm:w-auto"
                    >
                        Save
                    </button>
                    <a href="#" className="text-[#0a549c] w-full sm:w-auto text-center">Delete my account</a>
                </div>
                </div>
            </form>
        </main>
    );
}

