import React, { useState } from "react";

const TravelersSelection = ({ onClose, onTravelersUpdate, initialTravelers }) => {
  const [adults, setAdults] = useState(initialTravelers.adults);
  const [children, setChildren] = useState(initialTravelers.children);
  const [infants, setInfants] = useState(initialTravelers.infants);

  const MAX_TRAVELERS = 10; // Maximum limit of total travelers

  // Calculate the total number of travelers
  const totalTravelers = adults + children + infants;

  // Function to safely update the count of travelers while respecting the limit
  const handleUpdate = (type, value) => {
    if (type === "adults") {
      const newTotal = value + children + infants;
      if (newTotal <= MAX_TRAVELERS) setAdults(Math.max(1, value));
    } else if (type === "children") {
      const newTotal = adults + value + infants;
      if (newTotal <= MAX_TRAVELERS) setChildren(Math.max(0, value));
    } else if (type === "infants") {
      const newTotal = adults + children + value;
      if (newTotal <= MAX_TRAVELERS) setInfants(Math.max(0, value));
    }

    // Notify parent about updates
    onTravelersUpdate(adults, children, infants);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 w-80 fixed top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Travelers</h2>
        <button className="text-blue-500" onClick={onClose}>
          <i className="fas fa-times"></i>
        </button>
      </div>

      {/* Travelers Count */}
      <div className="space-y-4">
        {/* Adults */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <i className="fas fa-user text-lg"></i>
            <div>
              <div className="font-semibold">Adults</div>
              <div className="text-sm text-gray-500">12 years and above</div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <button
              className="text-gray-500"
              onClick={() => handleUpdate("adults", adults - 1)}
              disabled={adults <= 1}
            >
              <i className="fas fa-minus-circle"></i>
            </button>
            <span>{adults}</span>
            <button
              className="text-blue-500"
              onClick={() => handleUpdate("adults", adults + 1)}
              disabled={totalTravelers >= MAX_TRAVELERS}
            >
              <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>

        {/* Children */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <i className="fas fa-child text-lg"></i>
            <div>
              <div className="font-semibold">Children</div>
              <div className="text-sm text-gray-500">2 to 11 years</div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <button
              className="text-gray-500"
              onClick={() => handleUpdate("children", children - 1)}
              disabled={children <= 0}
            >
              <i className="fas fa-minus-circle"></i>
            </button>
            <span>{children}</span>
            <button
              className="text-blue-500"
              onClick={() => handleUpdate("children", children + 1)}
              disabled={totalTravelers >= MAX_TRAVELERS}
            >
              <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>

        {/* Infants */}
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <i className="fas fa-baby text-lg"></i>
            <div>
              <div className="font-semibold">Infants</div>
              <div className="text-sm text-gray-500">7 days to 23 months</div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <button
              className="text-gray-500"
              onClick={() => handleUpdate("infants", infants - 1)}
              disabled={infants <= 0}
            >
              <i className="fas fa-minus-circle"></i>
            </button>
            <span>{infants}</span>
            <button
              className="text-blue-500"
              onClick={() => handleUpdate("infants", infants + 1)}
              disabled={totalTravelers >= MAX_TRAVELERS}
            >
              <i className="fas fa-plus-circle"></i>
            </button>
          </div>
        </div>
      </div>

      {/* Total Travelers */}
      <div className="text-center mt-4 font-semibold text-gray-700">
        Total Travelers: {totalTravelers} / {MAX_TRAVELERS}
      </div>
    </div>
  );
};

export default TravelersSelection;

