export default function Wallet() {
    // Component logic goes here

    return (
<div className="p-8">
                    <div className="bg-white p-6 rounded-lg shadow-md mb-8">
                        <div className="flex items-center">
                            <i className="fas fa-signal text-gray-500 mr-2"></i>
                            <span className="text-gray-500">Balance</span>
                        </div>
                        <div className="text-4xl  text-[#0a549c] mt-2">PKR 0</div>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-2xl ">Transaction History</h2>
                        <button className="text-blue-600 border border-blue-600 rounded px-4 py-2">View All</button>
                    </div>
                    <div className="bg-white rounded-lg shadow-md">
                        <table className="min-w-full">
                            <thead>
                                <tr className="border-b">
                                    <th className="py-4 px-6 text-left">Date</th>
                                    <th className="py-4 px-6 text-left">Type</th>
                                    <th className="py-4 px-6 text-left">Amount</th>
                                    <th className="py-4 px-6 text-left">Order ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan="4" className="py-8 text-center text-gray-500">
                                        <div className="flex flex-col items-center h-96">
                                            <i className="fas fa-box-open text-4xl mb-2"></i>
                                            <span>No data</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

    );
}