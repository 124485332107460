import React from "react";

const Accordion = ({ question, answer, isOpen, onToggle }) => {
  return (
    <div className="border border-solid border-[#d9d9d9] ">
      <button
        onClick={onToggle}
        className="flex justify-between w-full p-2 "
      >
        <span className="font-medium hover:text-[#2270ae] font-reem text-gray-800">{question}</span>
        {isOpen ? <span>-</span> : <span>+</span>}
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-sm ${
          isOpen ? "grid-rows-[1fr] opacity-100 shadow-xl" : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className="overflow-hidden font-mulish text-[#262626] p-3 bg-white">{answer}</div>
      </div>
    </div>
  );
};

export default Accordion;
