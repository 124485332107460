import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Box, Grid, useMediaQuery } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTheme } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import VerifiedIcon from '@mui/icons-material/Verified';

const reviews = [
  {
    rating: 5,
    name: 'Anonymous',
    location: 'Lahore, Pakistan',
    time: '10 hours ago',
    content: 'I M REALLY IMPRESSED BY SASTA TICKET SPECIALY COORDINATOR MUHAMMAD SUFYAN SUCH A NICE GUY ND GUIDE ME PERFECTLY WITH AN AWESOME CONVERSATION WITH ME THANKS MUHAMMAD SUFYAN TAKE GOOD CARE OF YOURSELF STAY BLESSED ALWAYS STAY HAPP...',
  },
  {
    rating: 4,
    name: 'Anonymous',
    location: 'Unknown',
    time: '7 hours ago',
    content: 'None reason.',
  },
  {
    rating: 5,
    name: 'Anonymous',
    location: 'Karachi, Pakistan',
    time: '10 hours ago',
    content: 'Good Written Communication',
  },
  {
    rating: 5,
    name: 'Anonymous',
    location: 'Islamabad, Pakistan',
    time: '5 hours ago',
    content: 'Great Service and Support!',
  },
  {
    rating: 3,
    name: 'Anonymous',
    location: 'Quetta, Pakistan',
    time: '1 day ago',
    content: 'Service was okay, but can improve.',
  },
  {
    rating: 4,
    name: 'Anonymous',
    location: 'Peshawar, Pakistan',
    time: '2 days ago',
    content: 'Quick response and helpful support!',
  },
];

// Utility function to truncate text if it's too long
const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

function ReviewCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const reviewsPerView = isMobile ? 1 : 3;

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + reviewsPerView) % reviews.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - reviewsPerView < 0 ? reviews.length - reviewsPerView : prevIndex - reviewsPerView
    );
  };

  const visibleReviews = reviews.slice(currentIndex, currentIndex + reviewsPerView);

  return (
    <Box display="flex" justifyContent="center" sx={{backgroundColor: "#f7f8fb"}}>
      <Box display="flex" flexDirection="column" alignItems="center" maxWidth={1100} p={4}>
        <Typography gutterBottom align="center" mb={2}>
          <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
            <Typography variant="h6" fontWeight="bold">
              Excellent
            </Typography>
            <StarIcon style={{ color: '#FFCC00' }} />
            <StarIcon style={{ color: '#FFCC00' }} />
            <StarIcon style={{ color: '#FFCC00' }} />
            <StarIcon style={{ color: '#FFCC00' }} />
            <StarHalfIcon style={{ color: '#FFCC00' }} />
            <Typography variant="body2" color="textSecondary">
              based on 17,962 reviews
            </Typography>
          </Box>
        </Typography>

        <Box display="flex" alignItems="center" width="100%">
          <IconButton onClick={handlePrev}>
            <ArrowBackIosIcon />
          </IconButton>

          <Grid container spacing={2} justifyContent="center">
            {visibleReviews.map((review, index) => (
              <Grid item xs={12} sm={12 / reviewsPerView} key={index}>
                <Card
                  variant="outlined"
                  sx={{
                    height: 'auto',
                    minHeight: 180,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: isMobile ? '100%' : '310px', // Full width on mobile
                    position: 'relative',
                  }}
                >
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="h6" fontSize="1rem">
                      {review.name} ★★★★★
                    </Typography>
                    <Typography variant="body2" display="flex" alignItems="center" color="textSecondary" sx={{ fontSize: '10px' }}>
                      <VerifiedIcon sx={{ fontSize: '13px', color: "#0a549c", mr: 0.5 }} />
                      Verified Customer
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      {truncateText(review.content, 100)}
                    </Typography>
                  </CardContent>
                  <Box
                    position="absolute"
                    bottom={8}
                    right={8}
                    color="text.secondary"
                    fontSize="10px"
                  >
                    {review.location}, {review.time}
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          <IconButton onClick={handleNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default ReviewCarousel;
