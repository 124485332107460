import React, { useState } from "react";

const ClassSelector = ({ selectedClass, onClassChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const classes = ["Economy", "Premium Economy", "Business", "First"];

  return (
    <div className="relative">
      {/* Trigger Button */}
      <button
        className="bg-white text-blue-600 px-4 py-2 hover:bg-slate-200 flex items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedClass} <i className="fas fa-chevron-down ml-2"></i>
      </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <div className="absolute top-12 left-0 bg-white border border-gray-300 rounded-md shadow-lg w-64 p-4 z-50">
          {/* Header */}
          <div className="flex justify-between items-center mb-4">
            <span className="font-semibold text-lg">Class</span>
            <button onClick={() => setIsOpen(false)}>
              <i className="fas fa-times"></i>
            </button>
          </div>

          {/* Class Options */}
          {classes.map((cls) => (
            <div key={cls} className="flex items-center mb-2">
              <input
                type="radio"
                id={cls}
                name="class"
                value={cls}
                checked={selectedClass === cls}
                onChange={() => {
                  onClassChange(cls);
                  setIsOpen(false); // Close menu on selection
                }}
                className="form-radio text-blue-600"
              />
              <label htmlFor={cls} className="ml-2">
                {cls}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ClassSelector;
