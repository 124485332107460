import React from "react";

const AlertMessage = () => {
  return (
    <div className="bg-yellow-50 border  border-solid   border-yellow-500  p-4 mb-4">
      <div className="flex items-start gap-2">
        <svg
          className="w-5 h-5 flex-shrink-0 text-yellow-900 "
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v2m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        <p className="text-sm text-black leading-relaxed">
          Travelers with Visit, Tourist, and Business Visas must book their
          return flight with the same airline. Failure to do so will result in
          denied boarding.
        </p>
      </div>
    </div>
  );
};

export default AlertMessage;
