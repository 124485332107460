import React from 'react';
import { Box, Container, Typography, TextField, Button } from '@mui/material';

function SubscribeSection() {
    return (
        <Box
            component="section"
            bgcolor="white"
            py={6}
            sx={{
                
                paddingLeft: { xs: 2, sm: 'calc((100vw - 1200px) / 2)' },
                paddingRight: { xs: 2, sm: 'calc((100vw - 1200px) / 2)' },
            }}
        >
            <Container maxWidth="lg" sx={{border: '1px solid #f0f0f0', padding: "8px",                borderRadius: '12px',}}>
                <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    {/* Left Text Section */}
                    <Box flexBasis={{ xs: '100%', sm: '45%' }} mb={{ xs: 4, sm: 0 }} >
                        <Typography variant="h5" fontWeight="bold" sx={{ color: "#8c8c8c !important", }}>
                            Be the first one to get the deal.
                        </Typography>
                        <Typography variant="body1" sx={{ fontSize: '12px', color: "#8c8c8c !important", }}>
                            Subscribe now to know about our new deals, offers, and travel prices.
                        </Typography>
                    </Box>

                    {/* Right Input Section */}
                    <Box flexBasis={{ xs: '100%', sm: '55%' }} display="flex" alignItems="center">
                        <Box display="flex" gap={2} width="100%">
                            <TextField
                                type="email"
                                placeholder="Enter Email"
                                fullWidth
                                variant="outlined"
                                size="small"
                            />
                            <Button variant="contained" color="primary">
                                Subscribe
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default SubscribeSection;
