/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const PaymentMethod = ({ onNext }) => {
  // const formik = useFormik({
  //   initialValues: {
  //     cardName: "",
  //     cardNumber: "",
  //     expiry: "",
  //     cvv: "",
  //   },
  //   validationSchema: Yup.object({
  //     cardName: Yup.string().required("Name on card is required"),
  //     cardNumber: Yup.string()
  //       .required("Card number is required")
  //       .matches(/^[0-9]{16}$/, "Card number must be 16 digits"),
  //     expiry: Yup.string()
  //       .required("Expiry date is required")
  //       .matches(
  //         /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
  //         "Invalid expiry date format"
  //       ),
  //     cvv: Yup.string()
  //       .required("CVV is required")
  //       .matches(/^[0-9]{3}$/, "CVV must be 3 digits"),
  //   }),
  //   onSubmit: () => {
  //     onNext();
  //   },
  // });

  return (
    <div className="bg-white shadow-md rounded-md p-4">
      <div className="flex items-center justify-between border-b pb-4 mb-4">
        <div className="flex items-center space-x-2">
          <i className="fas fa-plane text-blue-600"></i>
          <span className="text-lg font-semibold">Booking</span>
          <i className="fas fa-chevron-right text-gray-400"></i>
          <span className="text-lg font-semibold text-blue-600">Payment</span>
          <i className="fas fa-chevron-right text-gray-400"></i>
          <span className="text-lg font-semibold">E-ticket</span>
        </div>
        <div className="text-sm text-gray-500">
          Finish Booking in{" "}
          <span className="text-blue-600">01 h : 59 m : 48 s</span>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-2">
          <h2 className="text-xl font-semibold mb-4">
            Select a payment method
          </h2>
          <div className="bg-gray-100 p-4 rounded-md mb-4">
            <div className="flex items-center space-x-2 mb-2">
              <i className="fas fa-lock text-green-500"></i>
              <span className="text-sm text-gray-600">Secure Payments</span>
              <i className="fas fa-undo text-yellow-500"></i>
              <span className="text-sm text-gray-600">Quick Refunds</span>
              <i className="fas fa-check-circle text-green-500"></i>
              <span className="text-sm text-gray-600">
                Trusted by 10 lac customers
              </span>
            </div>
            <div className="bg-white p-4 rounded-md shadow-md">
              <div className="flex items-center space-x-2 mb-4">
                <i className="fas fa-credit-card text-blue-600"></i>
                <span className="text-lg font-semibold">
                  Debit / Credit Card
                </span>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center space-x-2 mb-2">
                    <input
                      type="radio"
                      name="payment"
                      id="ibill"
                      className="form-radio"
                    />
                    <label
                      htmlFor="ibill"
                      className="flex items-center space-x-2"
                    >
                      <span>1Bill - Bank Transfer</span>
                      <span className="text-sm text-blue-600">
                        Save PKR 340
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center space-x-2 mb-2">
                    <input
                      type="radio"
                      name="payment"
                      id="easypaisa"
                      className="form-radio"
                    />
                    <label
                      htmlFor="easypaisa"
                      className="flex items-center space-x-2"
                    >
                      <span>Easypaisa</span>
                    </label>
                  </div>
                  <div className="flex items-center space-x-2 mb-2">
                    <input
                      type="radio"
                      name="payment"
                      id="jazzcash"
                      className="form-radio"
                    />
                    <label
                      htmlFor="jazzcash"
                      className="flex items-center space-x-2"
                    >
                      <span>JazzCash</span>
                    </label>
                  </div>
                  <div className="flex items-center space-x-2 mb-2">
                    <input
                      type="radio"
                      name="payment"
                      id="hbl"
                      className="form-radio"
                    />
                    <label
                      htmlFor="hbl"
                      className="flex items-center space-x-2"
                    >
                      <span>HBL Direct Transfer</span>
                      <span className="text-sm text-blue-600">
                        Save PKR 340
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="mb-4">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name on Card
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="card-number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Card Number
                    </label>
                    <input
                      type="text"
                      id="card-number"
                      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    />
                  </div>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label
                        htmlFor="expiry"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Expiry
                      </label>
                      <input
                        type="text"
                        id="expiry"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="cvv"
                        className="block text-sm font-medium text-gray-700"
                      >
                        CVV
                      </label>
                      <input
                        type="text"
                        id="cvv"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="voucher"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Voucher Code
                    </label>
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        id="voucher"
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                      />
                      <button className="bg-blue-600 text-white px-4 py-2 rounded-md">
                        Apply
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2 mb-4">
                    <i className="fas fa-wallet text-yellow-500"></i>
                    <span className="text-sm text-gray-700">Sasta Wallet</span>
                    <span className="text-sm text-gray-500">
                      Balance: PKR 0
                    </span>
                  </div>
                  <div className="text-sm text-gray-500 mb-4">
                    By selecting to complete this booking, I acknowledge that I
                    have read and accept the above Policy section containing
                    Fare Rules & Restrictions,{" "}
                    <a href="#" className="text-blue-600">
                      Terms of Use
                    </a>{" "}
                    and{" "}
                    <a href="#" className="text-blue-600">
                      Privacy Policy
                    </a>
                    .
                  </div>
                  <button
                    className="bg-gray-300 text-gray-500 px-4 py-2 rounded-md w-full"
                    disabled
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-white p-4 rounded-md shadow-md mb-4">
            <h2 className="text-lg font-semibold mb-4">Your Bookings</h2>
            <div className="flex items-center space-x-2 mb-2">
              <i className="fas fa-plane-departure text-blue-600"></i>
              <div>
                <div className="text-sm text-gray-700">Departing</div>
                <div className="text-sm text-gray-500">09 Oct, 2024</div>
              </div>
            </div>
            <div className="flex items-center space-x-2 mb-2">
              <i className="fas fa-plane text-blue-600"></i>
              <div>
                <div className="text-sm text-gray-700">
                  Serene Air ER-503 (OW)
                </div>
                <div className="text-sm text-gray-500">06:35 PM - 08:35 PM</div>
                <div className="text-sm text-gray-500">
                  Islamabad (ISB) - Nonstop - Karachi (KHI)
                </div>
                <div className="text-sm text-gray-500">Total: 40kg Pcs: 2</div>
              </div>
            </div>
          </div>
          <div className="bg-white p-4 rounded-md shadow-md mb-4">
            <h2 className="text-lg font-semibold mb-4">Price Summary</h2>
            <div className="flex justify-between mb-2">
              <span className="text-sm text-gray-700">
                Serene Air (Adult x1)
              </span>
              <span className="text-sm text-gray-700">PKR 17,875</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-sm text-gray-700">Credit Card fee</span>
              <span className="text-sm text-gray-700">PKR 340</span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-sm font-semibold text-gray-700">
                Price you pay
              </span>
              <span className="text-sm font-semibold text-blue-600">
                PKR 18,215
              </span>
            </div>
          </div>
          <div className="bg-white p-4 rounded-md shadow-md">
            <h2 className="text-lg font-semibold mb-4">
              Having trouble making a payment?
            </h2>
            <div className="flex items-center space-x-2">
              <i className="fas fa-phone-alt text-blue-600"></i>
              <span className="text-sm text-gray-700">
                Contact our helpline for assistance
              </span>
            </div>
            <div className="text-sm text-blue-600">(021) 111 172 782</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
