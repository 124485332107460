// useIsMobile.js
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * Custom hook to determine if the current screen size matches mobile.
 * @returns {boolean} True if the screen size is mobile, otherwise false.
 */
const useIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down('sm'));
};

export default useIsMobile;
