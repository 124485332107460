/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import BookingsDetail from "./components/Bookings";
import PurchaseHistory from "./components/PurchaseHistory";
import Wallet from "./components/Wallet";
import EditProfile from "./components/editProfile";
import { handleNavigation } from "../../Utils/utils-functions";

export default function UserProfile() {
  const [activeComponent, setActiveComponent] = useState("editProfile");

  // Function to render the correct component based on the active selection
  const renderComponent = () => {
    switch (activeComponent) {
      case "bookings":
        return <BookingsDetail />;
      case "purchaseHistory":
        return <PurchaseHistory />;
      case "wallet":
        return <Wallet />;
      default:
        return <EditProfile />;
    }
  };

  return (
    <div className="container w-11/12 p-3 flex flex-col md:flex-row">
      {/* Sidebar for Mobile and Large Screens */}
      <aside
        className={`${
          activeComponent === "editProfile"
            ? "hidden sm:hidden"
            : "block sm:block"
        } w-full md:w-1/4 bg-white p-6 shadow-md mb-4 md:mb-0 md:block`}
      >
        <div className="flex items-center mb-6">
          <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center">
            <i className="fas fa-user text-gray-400"></i>
          </div>
          <div className="ml-4">
            <p className="text-gray-600">User 728703</p>
            <a
              href=""
              onClick={() => setActiveComponent("editProfile")}
              className="text-blue-600 text-sm cursor-pointer"
            >
              Add Email
            </a>
          </div>
        </div>
        <nav className="space-y-4 ">
          <button
            onClick={() => setActiveComponent("bookings")}
            className={`block text-lg  ${
              activeComponent === "bookings"
                ? "text-blue-600 font-bold px-4 py-6  border-l-2 border-blue-600"
                : "text-[#595959] px-4 py-6 "
            }`}
          >
            Bookings
          </button>
          <button
            onClick={() => setActiveComponent("purchaseHistory")}
            className={`block text-lg ${
              activeComponent === "purchaseHistory"
                ? "text-blue-600 font-bold px-4 py-6  border-l-2 border-blue-600"
                : "text-[#595959] px-4 py-6 "
            }`}
          >
            Purchase History
          </button>
          <button
            onClick={() => setActiveComponent("wallet")}
            className={`block text-lg ${
              activeComponent === "wallet"
                ? "text-blue-600 font-bold  px-4 py-6 border-l-2 border-blue-600"
                : "text-[#595959] px-4 py-6 "
            }`}
          >
            Wallet
          </button>
        </nav>
        <button
          className="mt-6 w-full py-2 border border-blue-600 text-blue-600 rounded"
          onClick={() => handleNavigation("/")}
        >
          Sign Out
        </button>
      </aside>

      {/* Main Content */}
      <div className="w-full md:pl-4">{renderComponent()}</div>
    </div>
  );
}
