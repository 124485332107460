import React, { useState, useRef } from "react";
import Accordion from "./Accordian";

const FAQs = () => {
  const [selectedCategory, setSelectedCategory] = useState("COVID");
  const [openAccordion, setOpenAccordion] = useState(null); // Track the currently open accordion
  // Refs for each category
  const categoryRefs = {
    COVID: useRef(null),
    Cancellation: useRef(null),
    Booking: useRef(null),
    Payments: useRef(null),
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);

    // Smoothly scroll to the selected category
    if (categoryRefs[category]?.current) {
      categoryRefs[category].current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const categories = [
    { key: "COVID", label: "COVID" },
    { key: "Cancellation", label: "Cancellation and Refunds" },
    { key: "Booking", label: "Online Booking and Search" },
    { key: "Payments", label: "Payments" },
  ];


  return (
   
      <div className="min-h-screen bg-gray-100 font-mulish p-6">
        <h1 className="text-2xl font-reem text-[#08437d] pl-4 mb-8">Faq’s</h1>
        <div className="max-w-7xl mx-auto bg-white  font-reem">
          <h1 className="text-2xl text-[#08437d] text-center font-reem p-3 b-8">
            Frequently Asked Questions
          </h1>
          <div className="sticky top-0 z-50 bg-white justify-evenly flex space-x-4 p-4 md:p-6 overflow-x-auto">
            {categories.map((category) => (
              <button
                key={category.key}
                className={`py-2 px-4 ${selectedCategory === category.key
                    ? "border-b-2 border-blue-800"
                    : "text-[#262626]"
                  } hover:border-b-2 border-blue-800  transition-all duration-200`}
                onClick={() => handleCategoryClick(category.key)}
              >
                {category.label}
              </button>
            ))}
          </div>

        </div>

        <div ref={categoryRefs.COVID}>
          <h2 className={`text-xl font-reem ${selectedCategory === "COVID"
              ? "text-[#08437d]"
              : "text-[#0a549c]"
            } px-4 pt-4 mb-4`}
          >COVID</h2>
          <h2 className="text-xl  font-reem  text-[#0a549c] pl-4 mb-4">FAQs About COVID-19:</h2>

          <div className=" p-2  bg-[#F5F5F5]">
            <Accordion
              question="What are the countries I can visit at the moment?"
              answer="Entry for travelers from Pakistan in any particular country depends on the destination they are visiting. Every nation has different rules and regulations amidst the spread of COVID-19. It is advised that passengers must read all the rules and regulations before making travel plans. For further information please visit our Corona Travel Update page."
              isOpen={openAccordion === "COVID-1"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "COVID-1" ? null : "COVID-1")
              }
            />
            <Accordion
              question="Who can visit Pakistan at the moment?"
              answer="Passengers from any country can travel to Pakistan at the moment. Pakistan has eased travel restrictions for people who want to visit the country. However, each country has its own rules and regulations. Travelers from a particular country must go through travel advice for a seamless travel experience. People from the UK, UAE, KSA, Dubai, and America visit Pakistan the most. Similarly, Pakistanis tend to travel very frequently to Dubai, KSA, UAE, UK, America, and Turkey."
              isOpen={openAccordion === "COVID-2"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "COVID-2" ? null : "COVID-2")
              }
            />
            <Accordion question="What are the SOPs that I need to follow throughout my journey?"
              answer="All the passengers must have to follow the safety protocols while traveling PAN-Pakistan or out of Pakistan. General safety protocols include showing proof of being fully vaccinated, wearing a mask, using a sanitizer, or undergoing a PCR Test if demanded by the country you are traveling to or the airline with which you are traveling. Pakistan has abolished pre-boarding negative PCR for all inbound passengers."
              isOpen={openAccordion === "COVID-3"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "COVID-3" ? null : "COVID-3")
              } />

            <Accordion question="What are the necessary documents required to make my trip a hassle-free experience?"
              answer="The necessary documents include proof of being fully vaccinated, an ID card, a valid ticket, a valid passport, a valid visa, a negative PCR test report (if required by the destination) or any specific document issued by the airline for the safety of all passengers and crew."
              isOpen={openAccordion === "COVID-4"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "COVID-4" ? null : "COVID-4")
              } />

            <Accordion question="Is there any kind of form that I need to fill out before my journey?"
              answer="Currently, there is no form that needs to be filled out when boarding a flight within Pakistan. The Government of Pakistan has introduced a mobile app named Pass Track App to speed up your arrival process in Pakistan. Fill in your details within 48 hours before arriving in Pakistan and spend less time with public health and immigration authorities."
              isOpen={openAccordion === "COVID-5"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "COVID-5" ? null : "COVID-5")
              } />
          </div>
        </div>


        {/*FAQs About Cancellation and Refunds*/}
        <div ref={categoryRefs.Cancellation}>
          <h2 className={`text-xl font-reem ${selectedCategory === "Cancellation"
              ? "text-[#08437d]"
              : "text-[#0a549c]"
            } px-4 pt-4 mb-4`}
          >FAQs About Cancellation and Refunds</h2>
          <div className="p-2 bg-[#F5F5F5]">

            <Accordion question="How do I cancel my booking?"
              answer="Please call our customer support staff on 021-111-172-782 (021-11-11-SASTA) to cancel your flight/bus booking. They will guide you through a cancellation process and ensure the process is completed. You can Whatsapp us at +923047772782 or reach out to us via support@Ticketbazaar.pk"
              isOpen={openAccordion === "Cancellation-1"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Cancellation-1" ? null : "Cancellation-1")
              } />

            <Accordion question="Does Ticketbazaar charge any extra amount if I cancel my booking?"
              answer="We understand that sometimes things do not go as planned. Ticketbazaar does not charge any cancellation fee from the customers; we do not have any hidden charges. A customer shall pay a surcharge in case of late arrival.However, any extra charges charged to customers entirely depend on the airline policies.Each airline has its own rules and regulations in case of cancellation and refund. However, any penalty charged by the airline for cancellation or refund and any difference in fares shall be borne by the customer."
              isOpen={openAccordion === "Cancellation-2"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Cancellation-2" ? null : "Cancellation-2")
              } />

            <Accordion question="How will I get my refund after canceling my booking?"
              answer="Refunds are usually made in the same mode of payment through which a transaction was made. For example, if payment was made via card, the refunded amount will be reverted back in the same card or if the amount was deducted via bank account, the respective refunded amount will be given in the same account."
              isOpen={openAccordion === "Cancellation-3"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Cancellation-3" ? null : "Cancellation-3")
              } />

            <Accordion question="I booked my flight but due to some reason, I had to cancel my booking. How long does it take to get a refund?"
              answer="Refunds can take up to 5 working days to complete. The bookings made using Visa or Mastercard are processed by Ticketbazaar within 2-3 working days after initiation of refund. Please note that the time frame for a refund to reflect in the customer’s account ultimately depends on the card-issuing bank, which may take up to 45 working days. All other modes of payment are refunded and reflected within the given time."
              isOpen={openAccordion === "Cancellation-4"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Cancellation-4" ? null : "Cancellation-4")
              } />

            <Accordion question="What is Ticketbazaar’s refund process?"
              answer="When a customer requests a refund after canceling their booking, the refund process starts by processing the refund either through IBFT or via card. In the case of card transactions, the refund should be processed within 2 working days from Ticketbazaar and after the process is complete, it can take up to 45 days to get reflected in the customer’s card. In the Case of Bank Refunds, it should be processed within 48 hours or two working days. These transactions are reflected in the customer accounts right after they get processed."
              isOpen={openAccordion === "Cancellation-5"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Cancellation-5" ? null : "Cancellation-5")
              } />


          </div>
        </div>
        {/*FAQs About Online Booking & Search*/}

        <div ref={categoryRefs.Booking}>
          <h2 className={`text-xl font-reem ${selectedCategory === "Booking"
              ? "text-[#08437d]"
              : "text-[#0a549c]"
            } px-4 pt-4 mb-4`}
          >FAQs About Online Booking & Search:</h2>
          <div className="p-2 bg-[#F5F5F5]">

            <Accordion question="Why do I need to book flights online?"
              answer="Online booking is a great way to secure a seat or a room for yourself when you decide to travel. You need to book your flight  on Ticketbazaar beforehand to avoid any issue or inconvenience when you intend to travel. Ticketbazaar offers the most affordable fares and rates to the customers so that they can enjoy their trip without worrying about anything."
              isOpen={openAccordion === "Booking-1"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-1" ? null : "Booking-1")
              } />

            <Accordion question="How do I search for a flight on Ticketbazaar?"
              answer="Go to Ticketbazaar website and on the homepage you will find the search box for flights. Click on the desired tab and fill in the details such as traveling dates, departure and arrival cities, one way or round trip, number of passengers and the cabin class you want to travel. After putting in the details, click the “search” button. You will be redirected to the page where you can find the best options at the lowest possible prices as per your requirement. We now offer an option of traveling in a group too just by filling out a form where you need to add information similar to what you fill in the search box and you will hear from us shortly."
              isOpen={openAccordion === "Booking-2"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-2" ? null : "Booking-2")
              } />

            <Accordion question="If I don’t get the desired search result on the Ticketbazaar website, what should I do?"
              answer="For flights, try changing the dates, cabin class, or airline to get the best results. There might be a case that the facilities you want might currently be unavailable or fully booked which is why Ticketbazaar might not offer the exact search results. However, you will always find the best alternatives on the website for your search."
              isOpen={openAccordion === "Booking-3"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-3" ? null : "Booking-3")
              } />

            <Accordion question="How do I modify my search on Ticketbazaar?"
              answer="On the right side of the page, you will see the option of “change search” through which you can re-enter the information in the desired fields and you will get the results according to your preferences."
              isOpen={openAccordion === "Booking-4"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-4" ? null : "Booking-4")
              } />

            <Accordion question="I accidentally put in the wrong information while booking online. What can be done?"
              answer="For domestic flights this issue can be resolved by our Customer Support team. However, we advise you to recheck your entered details before moving forward with the booking.For international flights it is of extreme importance that you fill in the details accurately. If there is any error while filling the booking details, your order will be void and a new order needs to be created. You will have to bear all the penalty of canceling the previous order and difference of charge (if any) between old and new order."
              isOpen={openAccordion === "Booking-5"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-5" ? null : "Booking-5")
              } />

            <Accordion question="Do Ticketbazaar charges for reserving seats or meals?"
              answer="Ticketbazaar does not charge any extra any amount for reserving a seat or requesting for an inflight meal. However, if you are not able to do that you can request for the seat or meal at the counter while boarding for the flight."
              isOpen={openAccordion === "Booking-6"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-6" ? null : "Booking-6")
              } />

            <Accordion question="Do I need to book a seat for my infant?"
              answer="A child below the age of two is counted as an infant. They do not require a seat and can travel while sitting on the parent’s lap"
              isOpen={openAccordion === "Booking-7"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-7" ? null : "Booking-7")
              } />

            <Accordion question="Do prices change on Ticketbazaar?"
              answer="Prices change on a daily basis. All the prices are subject to price change as per the policies of airlines. You might see a flight at a lower price in one day but may see an increase in price for the same flight ."
              isOpen={openAccordion === "Booking-8"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-8" ? null : "Booking-8")
              } />

            <Accordion question="I booked my ticket via Ticketbazaar and my luggage has been left or misplaced by the airline. What can be done"
              answer="In such circumstances you can call or visit the airport to talk or contact the airline directly to know more about your luggage. Please carry your ticket while going to the airport or provide full and accurate details of your luggage along with your travel details so that it gets easy to search for your luggage."
              isOpen={openAccordion === "Booking-9"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Booking-9" ? null : "Booking-9")
              } />
          </div>
        </div>

        {/*FAQs About Payments:*/}
        <div ref={categoryRefs.Payments}>
          <h2
            className={`text-xl font-reem ${selectedCategory === "Payments"
                ? "text-[#08437d]"
                : "text-[#0a549c]"
              } px-4 pt-4 mb-4`}
          >FAQs About Payments:</h2>
          <div className="p-2 bg-[#F5F5F5]">

            <Accordion question="How do I pay for my booking made with Ticketbazaar.pk?"
              answer="Sataticket.pk offers a variety of payment options for their customers. You can pay through the following methods:
                   Debit/Credit Card: You can pay with your debit/credit cards on our website through a secure payment procedure. 
                   Pay at Branches: Your cash payments can be facilitated through our customer facilitation branches. 
                  Wallet Payments: You can also pay Ticketbazaar.pk through online wallet accounts such as EasyPaisa, JazzCash, and PayPro.
                  HBL Direct Transfer: If you own an HBL account, your payments can be made directly to Ticketbazaar.pk via your account.
                  NIFT ePay: An online payment option where you need to enter your bank details and OTP and you can make secure transactions. 
                  Wallet Payments:"
              isOpen={openAccordion === "Payments-1"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-1" ? null : "Payments-1")
              } />

            <Accordion question="Does Ticketbazaar.pk give me an option of paying later for a service I want to avail of?"
              answer="Currently, we do not provide this service. You need to pay the full amount against your booking. However, our valued customers will be informed if we introduce this service in the near future."
              isOpen={openAccordion === "Payments-2"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-2" ? null : "Payments-2")
              } />

            <Accordion question="Can I pay with someone else’s credit or debit card to secure my booking?"
              answer="Yes, you can pay for your bookings with the debit or credit card of your family and friends. Ticketbazaar.pk offers you a secure payment mode through which your transactions will be carried out in the safest manner."
              isOpen={openAccordion === "Payments-3"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-3" ? null : "Payments-3")
              } />

            <Accordion question="I was charged twice for my booking. What should I do?"
              answer="Ticketbazaar.pk only charges once for a service you want to avail of. However, if a customer is charged twice, the amount charged twice is reverted back within 24 hours in usual circumstances. Customers can also call and confirm their respective banks to get further details."
              isOpen={openAccordion === "Payments-4"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-4" ? null : "Payments-4")
              } />

            <Accordion question="I made the payment and completed my booking but I did not get an email regarding the confirmation of my booking. What is the next step?"
              answer="As soon as the payment is made, Ticketbazaar.pk issues a travel confirmation or an E-Document which contains your details. However, if you do not get any email, you can use our live chat option or call our customer support staff and they can guide you through the process and will ensure that the customer receives the document."
              isOpen={openAccordion === "Payments-5"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-5" ? null : "Payments-5")
              } />

            <Accordion question="I was making my booking but an unexpected error occurred. What should be the next step?"
              answer="You can contact Ticketbazaar.pk through our live chat, WhatsApp number +923047772782 or call us on our UAN number 021-111-172-782 (021-11-11-SASTA) and let us assist you to rectify the error."
              isOpen={openAccordion === "Payments-6"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-6" ? null : "Payments-6")
              } />

            <Accordion question="Do I have to pay any extra charges if I pay at the counter?"
              answer="Usually, a counter service charge has to be paid by the customer if making cash payments at the custom facilitation center. These charges are additional to the payment being made to avail of Ticketbazaar’s services."
              isOpen={openAccordion === "Payments-7"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-7" ? null : "Payments-7")
              } />

            <Accordion question="I have a discount voucher. How do I apply when making a booking?"
              answer="If a customer has a discount voucher, they can apply the voucher code in the payment section to enjoy additional discounts while booking service. Ticketbazaar.pk provides the lowest possible fares and rates to the valued customers."
              isOpen={openAccordion === "Payments-8"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-8" ? null : "Payments-8")
              } />

            <Accordion question="Will a price change affect the amount I need to pay for my booking?"
              answer="All fares and rates are subject to price change as per airline policies. Once payment is made, Ticketbazaar.pk will not charge its customers any extra amount if the fares or rates increase."
              isOpen={openAccordion === "Payments-9"}
              onToggle={() =>
                setOpenAccordion(openAccordion === "Payments-9" ? null : "Payments-9")
              } />
          </div>
        </div>
      </div>
  
  );
};

export default FAQs;