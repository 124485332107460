import React, { useState } from "react";

const MoreFiltersDropdown = () => {
  const [isOpen, setIsOpen] = useState(false); // Dropdown visibility
  const [price, setPrice] = useState(33650);
  const [arrivalTime, setArrivalTime] = useState([]);
  const [departureTime, setDepartureTime] = useState([]);
  const [airlines, setAirlines] = useState([]);

  // Toggle checkbox logic
  const toggleCheckbox = (list, setList, value) => {
    setList(
      list.includes(value)
        ? list.filter((item) => item !== value)
        : [...list, value]
    );
  };

  // Clear all filters
  const clearFilters = () => {
    setPrice(33650);
    setArrivalTime([]);
    setDepartureTime([]);
    setAirlines([]);
  };

  return (
    <div className="relative inline-block text-left">
      {/* Toggle Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-blue-600 text-sm focus:outline-none"
        aria-expanded={isOpen}
      >
        {isOpen ? "Less Filters" : "More Filters"} &#9662;
      </button>

      {/* Dropdown for Desktop */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-96 bg-white border border-gray-200 rounded-lg shadow-lg text-black z-50 hidden sm:block">
          <div className="p-4 space-y-4">
            {/* Filters */}
            <FiltersContent
              price={price}
              setPrice={setPrice}
              arrivalTime={arrivalTime}
              setArrivalTime={setArrivalTime}
              departureTime={departureTime}
              setDepartureTime={setDepartureTime}
              airlines={airlines}
              setAirlines={setAirlines}
              toggleCheckbox={toggleCheckbox}
              clearFilters={clearFilters}
              close={() => setIsOpen(false)}
            />
          </div>
        </div>
      )}

      {/* Modal for Mobile */}
      {isOpen && (
        <div className="fixed inset-0 bg-black text-black bg-opacity-50 z-50 flex justify-center items-center sm:hidden">
          <div className="bg-white w-full h-full p-4">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Filters</h2>
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-600 hover:text-gray-800"
              >
                Close
              </button>
            </div>
            {/* Filters */}
            <FiltersContent
              price={price}
              setPrice={setPrice}
              arrivalTime={arrivalTime}
              setArrivalTime={setArrivalTime}
              departureTime={departureTime}
              setDepartureTime={setDepartureTime}
              airlines={airlines}
              setAirlines={setAirlines}
              toggleCheckbox={toggleCheckbox}
              clearFilters={clearFilters}
              close={() => setIsOpen(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const FiltersContent = ({
  price,
  setPrice,
  arrivalTime,
  setArrivalTime,
  departureTime,
  setDepartureTime,
  airlines,
  setAirlines,
  toggleCheckbox,
  clearFilters,
  close,
}) => {
  return (
    <div className="space-y-4">
      {/* Arrival Time */}
      <div>
        <h4 className="font-semibold mb-2">Arrival Time</h4>
        {["12:00 AM - 06:00 AM", "06:00 AM - 12:00 PM", "12:00 PM - 06:00 PM", "06:00 PM - 12:00 AM"].map((time) => (
          <label key={time} className="block text-sm">
            <input
              type="checkbox"
              className="mr-2"
              checked={arrivalTime.includes(time)}
              onChange={() => toggleCheckbox(arrivalTime, setArrivalTime, time)}
            />
            {time}
          </label>
        ))}
      </div>

      {/* Departure Time */}
      <div>
        <h4 className="font-semibold mb-2">Departure Time</h4>
        {["12:00 AM - 06:00 AM", "06:00 AM - 12:00 PM", "12:00 PM - 06:00 PM", "06:00 PM - 12:00 AM"].map((time) => (
          <label key={time} className="block text-sm">
            <input
              type="checkbox"
              className="mr-2"
              checked={departureTime.includes(time)}
              onChange={() => toggleCheckbox(departureTime, setDepartureTime, time)}
            />
            {time}
          </label>
        ))}
      </div>

      {/* Airlines */}
      <div>
        <h4 className="font-semibold mb-2">Airlines</h4>
        {["Airblue", "Fly Jinnah", "PIA", "Airsial"].map((airline) => (
          <label key={airline} className="block text-sm">
            <input
              type="checkbox"
              className="mr-2"
              checked={airlines.includes(airline)}
              onChange={() => toggleCheckbox(airlines, setAirlines, airline)}
            />
            {airline}
          </label>
        ))}
      </div>

      {/* Price Range */}
      <div>
        <h4 className="font-semibold mb-2">Price Range</h4>
        <div className="flex items-center space-x-4">
          <input
            type="range"
            min="25445"
            max="40000"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full"
          />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-16 border rounded p-1"
          />
        </div>
      </div>

      {/* Refundable */}
      <div>
        <label className="inline-flex items-center text-sm">
          <input type="checkbox" className="mr-2" />
          Refundable
        </label>
      </div>

      {/* Actions */}
      <div className="flex justify-between items-center mt-4">
        <button onClick={clearFilters} className="text-sm text-gray-600 hover:underline">
          Clear All
        </button>
        <button onClick={close} className="px-3 py-1 text-sm bg-blue-600 text-white rounded hover:bg-blue-700">
          Apply
        </button>
      </div>
    </div>
  );
};

export default MoreFiltersDropdown;



