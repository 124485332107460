export default function BookingsDetail() {
    // Component logic goes here

    return (
        // JSX code for rendering the component goes here
                
        <div className="p-4 md:p-8">
            <h1 className="text-2xl md:text-3xl mb-4 md:mb-6">Your Bookings</h1>

            {/* Search Input */}
            <div className="mb-6">
                <div className="relative">
                    <input
                        type="text"
                        placeholder="Search by Order ID"
                        className="w-full p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button className="absolute right-3 top-3 text-gray-500">
                        <i className="fas fa-search"></i>
                    </button>
                </div>
            </div>

            {/* Booking Card */}
            <div className="bg-white rounded-lg shadow-md p-4 flex flex-col md:flex-row items-start md:items-center justify-between h-auto md:h-96">
                <div className="flex items-center mb-4 md:mb-0">
                    <i className="fas fa-plane text-gray-500 mr-2"></i>
                    <span className="text-gray-500">Flight</span>
                </div>

                <div className="flex flex-col md:flex-row items-start md:items-center">
                    <img
                        src="https://placehold.co/50x50"
                        alt="Serene Air logo"
                        className="w-12 h-12 mr-4 mb-4 md:mb-0"
                    />
                    <div className="flex flex-col md:mr-4 mb-4 md:mb-0">
                        <div className="text-xl md:text-2xl">06:35 PM</div>
                        <div className="text-gray-500">Serene Air</div>
                    </div>
                    <div className="mx-0 md:mx-4 mb-4 md:mb-0">
                        <div className="bg-gray-200 text-gray-700 px-2 py-1 rounded-lg">
                            2h
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="text-xl md:text-2xl">08:35 PM</div>
                        <div className="text-gray-500">
                            Islamabad (ISB) - Nonstop - Karachi (KHI)
                        </div>
                    </div>
                </div>

                <div className="text-left md:text-right">
                    <div className="text-gray-500">07 Oct, 2024</div>
                    <div className="text-gray-500">
                        Order ID <span className="font-bold">3483317</span>
                    </div>
                    <div className="bg-gray-200 text-gray-700 px-2 py-1 rounded-lg inline-block mt-2">
                        Order Expired
                    </div>
                    <button className="bg-blue-600 text-white px-4 py-2 rounded-lg mt-2">
                        View
                    </button>
                </div>
            </div>
        </div>
    );
}
