import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // MUI Menu icon
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SignInDialog from "../pages/SignIn/SignInPage";
import CloseIcon from "@mui/icons-material/Close";
import { Search } from "@mui/icons-material";
import { handleNavigation } from "../Utils/utils-functions";
import useIsMobile from "../reusablecomponents/useIsMobile";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const Header = () => {
  const isMobile = useIsMobile();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };


  const drawerItems = [
    { text: "Our Branches", path: "/contactus" },
    { text: "Flights", path: "/flight" },
    { text: "Signin / Login", action: () => setOpenSignInDialog(true) },
    // { text: "Search Bookings", path: "/search-booking" },
  ];
  return (
    <>
      <SignInDialog
        open={openSignInDialog}
        setOpen={setOpenSignInDialog}
        onClose={() => setOpenSignInDialog(false)}
      />
      <AppBar position="static" color="transparent" elevation={0} sx={{padding: "0px calc((100vw - 1200px) / 2)",}}>
        <Toolbar sx={{ justifyContent: "space-between", height: '81px',paddingLeft: '0px !important',paddingRight: '0px !important', color:'#0a549c' }} >
          <IconButton
          sx={{marginLeft:'20px'}}
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, fontSize: "24px" ,color:'#0a549c'}}
          >
            <span
              style={{
                textDecoration: "none",
                color:'#0a549c',
                cursor: "pointer",
                
              }}
              onClick={() => handleNavigation("/")}
            >
              Ticketbazaar.pk
            </span>
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center",  }}>
            {/* Search Bookings */}
            {!isMobile &&
              <Typography
              onClick={() => handleNavigation("/search-booking")}
              color="inherit"
              className="flex justify-center items-center" 
              sx={{ margin: "0 10px",color:'#0a549c', fontSize: '1rem' }}
            >
              <Search sx={{margin:'0px 8px 0px 0px'}} />
              <span style={{display: 'flex',flexDirection: 'column', fontWeight:'500',cursor: "pointer"}}>
                Search Bookings
              </span>
            </Typography>
            }
            {/* Contact Information */}
            {/* {!isMobile &&
              <>
                <Typography
                component={'a'}
                href="tel:+9221111172782"
                color="inherit"
                className="flex justify-center items-center" 
                sx={{ margin: "0 15px",color:'#0a549c', fontSize: '1rem' }}
              >
                <Call sx={{margin:'-6px 8px 0px 0px'}} />
                <span style={{display: 'flex',flexDirection: 'column'}}>
                <span style={{fontSize: "14px",}} className="font-mulish">Call 24/7</span>
                  <span style={{textDecoration: "underline", textUnderlineOffset: "2px", color: '#0a549c'}}>
                  +92 21-111-172-782
                </span>
                </span>
              </Typography>
                <Typography
                component={'a'}
                href="https://wa.me/923047772782"
                color="inherit"
                className="flex justify-center items-center" 
                sx={{ margin: "0 10px",color:'#0a549c', fontSize: '1rem' }}
              >
                <WhatsApp sx={{margin:'-6px 8px 0px 0px'}} />
                <span style={{display: 'flex',flexDirection: 'column'}}>
                <span style={{fontSize: "14px",}} className="font-mulish">WhatsApp 24/7</span>
                  <span style={{textDecoration: "underline", textUnderlineOffset: "2px", color: '#0a549c'}}>
                  +92 304 777 2782
                </span>
                </span>
              </Typography>
              </>
            } */}
            {/* Currency Dropdown */}
            {/* {isMobile ? (
              <>
                <Button onClick={handleOpen}>
                  {currency}
                </Button>
                <Dialog
                  fullScreen
                  sx={{ top: '50%' }}
                  open={openCurrencyDialog}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                >
                  <List>
                    {currencies.map((currency) => (
                      <ListItem
                        button
                        key={currency.value}
                        onClick={handleCurrencyChange}
                      >
                        <ListItemText primary={currency.label} />
                      </ListItem>
                    ))}
                  </List>
                </Dialog>
              </>
            ) : (
              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  mx: 2,
                  '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
                }}
              >
                <Select
                  value={currency}
                  onChange={handleCurrencyChange}
                  displayEmpty
                >
                  {currencies.map((currency) => (
                    <MenuItem key={currency.value} value={currency.value}>
                      <Box display="flex" alignItems="center" gap={1}>
                        <img src={currency.flag} alt={`${currency.label} flag`} width="20" height="15" />
                        <Typography sx={{color:"#08437d !important"}}>
                        {currency.label}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

            )} */}

            {isMobile ? (
              // Render IconButton for mobile screens
              <IconButton
                color="inherit"
                onClick={() => setOpenSignInDialog(true)}
                sx={{ margin: "0 10px" }}
              >
                <AccountCircleRoundedIcon />
              </IconButton>
            ) : (
              // Render full Button for larger screens
              <Typography
                color="inherit"
                onClick={() => setOpenSignInDialog(true)}
                sx={{ margin: "0 10px",color:'#0a549c', fontSize: '1rem' ,cursor: "pointer"}}
              >
                <PersonOutlineIcon sx={{margin:'-6px 8px 0px 0px'}} />
                Sign In
              </Typography>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            marginTop: "81px", // Set the marginTop
            width: isMobile ? "70%" : "250px", // Set the width
          },
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}
        >
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {drawerItems.map((item, index) => (
            <ListItem
              button
              key={index}
              onClick={() => {
                if (item.action) {
                  item.action();
                } else {
                  handleNavigation(item.path);
                }
                setDrawerOpen(false); // Close the drawer after clicking
              }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
