import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import HotelIcon from '@mui/icons-material/Hotel';
import GasMeterRoundedIcon from '@mui/icons-material/GasMeterRounded';

function StatsSection() {
  const statsData = [
    {
      icon: <FlightTakeoffIcon sx={{ color: "#007aff", fontSize: 40 }} />,
      count: '700k+',
      label: 'Flights booked',
    },
    {
      icon: <DirectionsBusIcon sx={{ color: "#007aff", fontSize: 40 }} />,
      count: '300k+',
      label: 'Buses booked',
    },
    {
      icon: <HotelIcon sx={{ color: "#007aff", fontSize: 40 }} />,
      count: '50k+',
      label: 'Hotels booked',
    },
    {
      icon: <GasMeterRoundedIcon sx={{ color: "#007aff", fontSize: 40 }} />,
      count: '20m+',
      label: 'Kilometres traveled',
    },
  ];

  return (
    <Box
      component="section"
      bgcolor="white"
      py={6}
      sx={{
        paddingLeft: { xs: 2, sm: 'calc((100vw - 1200px) / 2)' },
        paddingRight: { xs: 2, sm: 'calc((100vw - 1200px) / 2)' },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between" alignItems="center">
          {statsData.map((stat, index) => (
            <Grid item xs={6} sm={3} md={3} key={index}>
              <Box display="flex" alignItems="center" gap={2}>
                {stat.icon}
                <Box>
                  <Typography variant="h5" >{stat.count}</Typography>
                  <Typography variant="body2" color="textSecondary">{stat.label}</Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default StatsSection;
