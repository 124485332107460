import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, InputAdornment, MenuItem, Box } from "@mui/material";

const countryCodes = [
  { code: "+93", flag: "🇦🇫", name: "Afghanistan" },
  { code: "+355", flag: "🇦🇱", name: "Albania" },
  { code: "+213", flag: "🇩🇿", name: "Algeria" },
  { code: "+1", flag: "🇺🇸", name: "United States" },
  { code: "+91", flag: "🇮🇳", name: "India" },
  { code: "+92", flag: "🇵🇰", name: "Pakistan" },
  { code: "+44", flag: "🇬🇧", name: "United Kingdom" },
];

const PhoneNumberInput = ({
  label,
  defaultCountryCode,
  mobileNumber,
  setMobileNumber,
}) => {
  const [countryCode, setCountryCode] = useState(defaultCountryCode || "+92");

  // Handle country code change
  const handleCountryCodeChange = (e) => {
    const newCode = e.target.value;
    setCountryCode(newCode);

    // Adjust mobileNumber if necessary
    const numberWithoutCode = mobileNumber.replace(/^\+\d+/, ""); // Remove old country code
    setMobileNumber(newCode + numberWithoutCode);
  };

  // Handle mobile number change
  const handleMobileChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Allow only digits
    const fullNumber = `${countryCode}${input}`;

    // Allow updating state only if valid or partial input
    if (
      fullNumber.startsWith(countryCode) &&
      fullNumber.length <= countryCode.length + 10 // Adjust length as per format
    ) {
      setMobileNumber(fullNumber);
    }
  };

  // Full validation for the current country code
  const isValidPhoneNumber = () => {
    const regex = new RegExp(
      `^\\${countryCode}[0-9]{${countryCode === "+92" ? 10 : 7}}$`
    );
    return regex.test(mobileNumber);
  };

  return (
    <Box>
      <TextField
        label={label || "Mobile Number"}
        value={mobileNumber.replace(countryCode, "")} // Show number without country code
        onChange={handleMobileChange}
        variant="outlined"
        fullWidth
        error={!isValidPhoneNumber() && mobileNumber !== ""}
        helperText={
          !isValidPhoneNumber() && mobileNumber !== ""
            ? "Invalid phone number. Check the format."
            : ""
        }
        sx={{
          "& .MuiInputBase-root": {
            height: "40px", // Adjusting height of the input field
          },
          width: "370px",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                select
                value={countryCode}
                onChange={handleCountryCodeChange}
                variant="standard"
                style={{
                  width: "80px",
                  border: "none",
                  padding: "0px",
                  margin: "0px",
                }}
                SelectProps={{
                  style: {
                    paddingRight: "0px",
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                }}
              >
                {countryCodes.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.flag} {option.code}
                  </MenuItem>
                ))}
              </TextField>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.string,
  defaultCountryCode: PropTypes.string,
  mobileNumber: PropTypes.string.isRequired,
  setMobileNumber: PropTypes.func.isRequired,
};

export default PhoneNumberInput;
