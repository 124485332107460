import React, { useState, useEffect } from "react";

const Timer = () => {
  const [timeLeft, setTimeLeft] = useState(4 * 60 * 60); // 4 hours in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Format time into HH:MM:SS
  const formatTime = (time) => {
    const hours = String(Math.floor(time / 3600)).padStart(2, "0");
    const minutes = String(Math.floor((time % 3600) / 60)).padStart(2, "0");
    const seconds = String(time % 60).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className=" px-4 py-2 ">
      <span className="font-medium inline-block">Finish booking in</span>{" "}
      <span  className="font-medium text-blue-600 font-mono"
        style={{ width: "100px", display: "inline-block", textAlign: "center" }}>
            {formatTime(timeLeft)}</span>
    </div>
  );
};

export default Timer;
