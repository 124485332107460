import React, { useRef, useState } from "react";

const TermsAndConditions = () => {
  // Refs for each category
  const generalRef = useRef(null);
  const airportRef = useRef(null);
  const insuranceRef = useRef(null);
  const refundRef = useRef(null);
  const dateChangeRef = useRef(null);
  const rewardsRef = useRef(null);

  const [activeTab, setActiveTab] = useState("General Booking Terms");

  const sections = [
    { name: "General Booking Terms", ref: generalRef },
    { name: "Airport Transfers", ref: airportRef },
    { name: "Insurance Terms", ref: insuranceRef },
    { name: "Sasta Refund", ref: refundRef },
    { name: "Free Date Change", ref: dateChangeRef },
    { name: "Sasta Rewards", ref: rewardsRef },
  ];

  const scrollToSection = (ref, tabName) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
    setActiveTab(tabName);
  };

  return (
    <div className="max-w-8xl mx-auto p-6 bg-gray-100">
      {/* Page Title */}
      <h1 className="text-2xl text-[#08437d] font-reem mb-6">Terms and Conditions</h1>

      {/* Navigation Menu */}
      <h2 className="text-2xl text-center bg-[#fff] pt-4  font-reem text-[#08437d]">Terms and Conditions</h2>
      <div className="sticky top-0 bg-[#fff] py-4 shadow-md z-10">
        <div className="flex space-x-4 justify-evenly overflow-x-auto font-reem">
          {sections.map((section) => (
            <button
              key={section.name}
              onClick={() => scrollToSection(section.ref, section.name)}
              className={`px-4 py-2 transition-all duration-200 ${activeTab === section.name
                ? "border-b-2 border-blue-800"
                : " hover:border-b-2 border-blue-800  transition-all duration-200"
                }`}
            >
              {section.name}
            </button>
          ))}
        </div>
      </div>

      {/* Content Sections */}
      <div className="mt-6 space-y-12">
        <div ref={generalRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Travel Terms and Conditions
          </h2>
          <p className="text-[#262626] font-mulish">
            These website standard terms and conditions (‘Terms and Conditions’) contained herein on this webpage, shall govern your use of this website, including all the pages within this website https://www.Ticketbazaar.pk (‘Website’). These Terms and Conditions, as may be amended from time to time, apply in full force and effect to your use of this Website. By accessing, browsing and using this Website and / or completing a reservation or making an online payment, you unconditionally and irrevocably accept and agree to these Terms and Conditions governing your use of the Website (the “Agreement”). Should you not agree to be bound by these Terms and Conditions laid herein, you shall not access or use any part of the Website. These Terms constitute a binding legal agreement between you as an individual user and Ticketbazaar (Pvt.) Limited (where the context so permits, the “Owner” / “Company”).

            This Website contains material which is owned by or licensed and or copyrighted in favor of the Owner.  This material includes, but is not limited to, the design, layout, look, appearance and graphics and reproduction thereof is prohibited.
          </p>
        </div>

        <div ref={airportRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Airport Transfers
          </h2>
          <p className="text-[#262626] font-mulish">Content for Airport Transfers...</p>
        </div>

        <div ref={insuranceRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Insurance Terms
          </h2>
          <p className="text-[#262626] font-mulish">Content for Insurance Terms...</p>
        </div>

        <div ref={refundRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Sasta Refund
          </h2>
          <p className="text-[#262626] font-mulish">Content for Sasta Refund...</p>
        </div>

        <div ref={dateChangeRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Free Date Change
          </h2>
          <p className="text-[#262626] font-mulish">Content for Free Date Change...</p>
        </div>

        <div ref={rewardsRef} className="bg-white shadow rounded border border-solid border-[#d9d9d9]">
          <h2 className="text-xl font-reem text-[#08437d] bg-[#f5f5f5] px-4 py-2 rounded-t border-b border-gray-300">
            Sasta Rewards
          </h2>
          <p className="text-[#262626] font-mulish">Content for Sasta Rewards...</p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
