import React from "react";

const Footer = () => {
  return (
<div className="flex flex-col items-center justify-center py-8">
      {/* Payment and Security Logos */}
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        <img src="images/footer-norton.png" alt="Norton Secured logo" className="h-8" />
        <img src="images/footer-jazzcash.png" alt="JazzCash logo" className="h-8" />
        <img src="images/footer-easypaisa.png" alt="Easypaisa logo" className="h-8" />
        <img src="images/footer-hbl.png" alt="HBL logo" className="h-8" />
        <img src="images/footer-western.png" alt="Western Union logo" className="h-8" />
        <img src="images/footer-paypak.png" alt="PayPak logo" className="h-8" />
        <img src="images/footer-visa.png" alt="Visa logo" className="h-8" />
      </div>

      {/* Navigation Links */}
      <div className="flex flex-wrap justify-center gap-4 text-blue-800 text-sm mb-4">
        <a href="/" className="hover:underline">Home</a>
        <a href="/aboutus" className="hover:underline">About Us</a>
        <a href="/contactus" className="hover:underline">Contact Us</a>
        <a href="/search-booking" className="hover:underline">Search Bookings</a>
        <a href="/faqs" className="hover:underline">FAQ</a>
        <a href="/privacypolicy" className="hover:underline">Privacy Policy</a>
        <a href="/careers" className="hover:underline">Careers</a>
        <a href="/terms-and-conditions" className="hover:underline">Terms & Conditions</a>
        <a href="/sastarefund" className="hover:underline">Sasta Refund</a>
      </div>

      {/* Copyright Notice */}
      <div className="text-gray-600 text-xs mb-4 text-center">
        Copyright © 2024 Ticketbazaar Private Limited. All rights reserved.
      </div>

      {/* Social Media Links */}
      <div className="flex space-x-4">
        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-gray-800">
          <i className="fab fa-twitter"></i>
        </a>
      </div>
    </div>
  );
};

export default Footer;
