import React ,{useState} from "react";


const TravelerForm = () => {

    const [countries, setCountries] = useState([
        "United States", "Canada", "United Kingdom", "Australia", "Germany",
        "France", "Italy", "Spain", "India", "China", "Japan", "Brazil",
        "Mexico", "South Africa", "Russia", "Netherlands", "Sweden",
        "Norway", "Finland", "Denmark"
    ]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        // Check if the value is a new country and not already in the list
        if (value && !countries.includes(value)) {
            setCountries([...countries, value]);
        }
    };
    return (
        <div >
            <form>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div>
                        <label htmlFor="savedTravelers" className="block text-sm font-medium text-black ">
                            Saved Travelers
                        </label>
                        <input
                            list="savedTravelersOptions"
                            id="savedTravelers"
                            placeholder="Select or type"
                            className="mt-1 block w-full  focus:ring-blue-500 focus:border-blue-500 sm:text-sm  h-12 p-1 border border-solid border-gray-200"
                        />
                        <datalist id="savedTravelersOptions">

                        </datalist>
                    </div>

                    <div>
                        <span className="block text-sm font-medium text-gray-700">Select Title</span>
                        <div className="flex justify-around gap-4 mt-2 h-12 p-1 border border-solid border-gray-200">
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="title"
                                    value="Mr"
                                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 text-sm text-gray-700">Mr</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="title"
                                    value="Mrs"
                                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 text-sm text-gray-700">Mrs</span>
                            </label>
                            <label className="flex items-center">
                                <input
                                    type="radio"
                                    name="title"
                                    value="Ms"
                                    className="h-4 w-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                />
                                <span className="ml-2 text-sm text-gray-700">Ms</span>
                            </label>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="givenName" className="block text-sm font-medium text-gray-700">
                            Given Name
                        </label>
                        <input
                            type="text"
                            id="givenName"

                            className="mt-1 block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm  h-12 p-1 border border-solid border-gray-200"
                        />
                        <span className="text-xs font-mulish text-gray-400">Enter name as per Passport to avoid boarding issues</span>
                    </div>

                    <div>
                        <label htmlFor="surname" className="block text-sm font-medium text-gray-700">
                            Surname
                        </label>
                        <input
                            type="text"
                            id="surname"

                            className="mt-1 block w-full  focus:ring-blue-500 focus:border-blue-500 sm:text-sm  h-12 p-1 border border-solid border-gray-200"
                        />
                        <span className="text-xs font-mulish  text-gray-400">Enter name as per Passport to avoid boarding issues</span>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Date of Birth</label>
                        <div className="flex gap-2 mt-1  h-12 p-1 border border-solid border-gray-200">
                            <input type="text" placeholder="Date" className=" p-2 text-black w-1/3" list="dates" />
                            <datalist id="dates">
                                {Array.from({ length: 31 }, (_, i) => (
                                    <option key={i} value={i + 1} />
                                ))}
                            </datalist>
                            <input type="text" placeholder="Month" className=" p-2 text-black w-1/3" list="months" />
                            <datalist id="months">
                                {["January",
                                    "February",
                                    "March",
                                    "April",
                                    "May",
                                    "June",
                                    "July",
                                    "August",
                                    "September",
                                    "October",
                                    "November",
                                    "December"].map((month, index) => (
                                        <option key={index} value={month} />
                                    ))}
                            </datalist>
                            <input type="text" placeholder="Year" className=" p-2 text-black w-1/3" list="years" />
                            <datalist id="years">
                                {Array.from({ length: 100 }, (_, i) => (
                                    <option key={i} value={2023 - i} />
                                ))}
                            </datalist>
                        </div>
                    </div>

                    <div>
                        <label htmlFor="nationality" className="block text-sm font-medium text-gray-700">
                            Nationality
                        </label>
                        <input
                            id="nationality"
                            placeholder="Nationality"
                            className="mt-1 block w-full  focus:ring-blue-500 focus:border-blue-500 sm:text-sm h-12 p-1 border border-solid border-gray-200"
                            list="countries"
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        <datalist id="countries">
                            {countries.map((country, index) => (
                                <option key={index} value={country} />
                            ))}
                        </datalist>

                    </div>

                    <div>
                        <label htmlFor="frequentFlyer" className="block text-sm font-medium text-gray-700">
                            Frequent Flyer Number (optional)
                        </label>
                        <input
                            type="text"
                            id="frequentFlyer"

                            className="mt-1 block w-full  focus:ring-blue-500 focus:border-blue-500 sm:text-sm  h-12 p-1 border border-solid border-gray-200"
                        />
                        <span className="text-xs font-mulish  text-gray-400">Loyalty points/miles won't be added for incorrect entries.</span>
                    </div>
                </div>


            </form>
        </div>
    );
};

export default TravelerForm;
