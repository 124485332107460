import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Custom primary color (blue)
      light: '#63a4ff', // Light variant of the primary color
      dark: '#004ba0', // Dark variant of the primary color
      contrastText: '#ffffff', // Text color for primary buttons, etc.
    },
    secondary: {
      main: '#f50057', // Custom secondary color (pink)
      light: '#ff5983',
      dark: '#bb002f',
      contrastText: '#ffffff',
    },
    // You can define more colors like error, warning, info, success if needed
    background: {
      default: '#f4f6f8', // Default background color
      paper: '#ffffff',   // Background color for paper elements
    },
    text: {
      primary: '#333333',  // Default text color
      secondary: '#666666', // Secondary text color
    },
  },
  typography: {
    fontFamily: "'Reem Kufi', sans-serif", // Custom font
    button: {
      fontWeight: 700, // Bold text for buttons
    },
  },
  shape: {
    borderRadius: 8, // Global border-radius for rounded elements
  },
});

export default theme;
