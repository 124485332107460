import React from "react";
import Search from "./FlightSearchResults";
import './Home.css';
import ReviewCarousel from "./Reviews";
import StatsSection from "./Statistics";
import SubscribeSection from "./Subscriber";
import ScrollableImageGrid from "./ScrollableImageGrid";

const HomePage = () => {

  // const items = [
  //   {
  //     imgSrc: '/images/customer-support-24-7.7576599.svg',
  //     altText: 'Customer Support',
  //     label: '24/7 Customer Support',
  //   },
  //   {
  //     imgSrc: '/images/refunds-within-48-hours.ab68578.svg',
  //     altText: 'Refunds',
  //     label: 'Refunds within 48 hours',
  //   },
  //   {
  //     imgSrc: '/images/secure-transaction-guaranteed.5d41198.svg',
  //     altText: 'Secure Transactions',
  //     label: 'Secure Transaction Guaranteed',
  //   },
  // ];

  return (
    <>
      <Search />
      {/* <CustomerSupportSection /> */}
      {/* <DownloadSection /> */}
      <ReviewCarousel />
      <ScrollableImageGrid />
      <StatsSection />
      {/* <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-xl font-bold mb-4">Featured Partners</h2>
          <div className="flex space-x-4 overflow-x-auto">
            <img
              src="https://placehold.co/100x50"
              alt="Partner 1"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 2"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 3"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 4"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 5"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 6"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 7"
              className="h-12"
            />
            <img
              src="https://placehold.co/100x50"
              alt="Partner 8"
              className="h-12"
            />
          </div>
        </div>
      </section> */}
      <SubscribeSection />
    </>
  );
};

export default HomePage;
