import React from "react";
import { Container, Box, Typography, TextField, Button } from "@mui/material";

function SearchBooking() {
  return (
    <div className=" bg-[#f7f8fb]"> 
    <Container maxWidth="sm" sx={{ py:10, mb:4,border: "2px ", borderRadius: '20px' , backgroundColor:'#f7f8fb' }}>
      <Box textAlign="center" sx={{backgroundColor:'white', border:"1px solid #f0f0f0" }}>
        <Typography variant="h4" sx={{fontSize:" 1.875rem !important" , pt:6 }} gutterBottom>
          Search for your bookings
        </Typography>
        <form style={{padding:"14px", fontFamily:'Mulish', width: '100%'}}>
          <div className=" w-[75%] m-auto">
          <TextField  
            fullWidth
            label="Order ID"
            variant="outlined"
            placeholder="e.g. 123456"
            margin="normal"
            helperText="Your Order ID is emailed with booking confirmation."
            FormHelperTextProps={{
              sx: {
                fontFamily: "'Mulish', sans-serif", // Apply the 'Mulish' font family
              },
            }}
          />
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            variant="outlined"
            placeholder="e.g. name@gmail.com"
            margin="normal"
            helperText="The email address entered during booking."
            FormHelperTextProps={{
              sx: {
                fontFamily: "'Mulish', sans-serif", // Apply the 'Mulish' font family
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2, mb:2 ,height:'40', paddingLeft:7, paddingRight:7 ,backgroundColor:"#0a549c","&:hover": {
          backgroundColor: "#5390c9", // Background color on hover
        },  }}
            
          >
            Search
          </Button>
          </div>
        </form>
      </Box>
    </Container>
    </div>
  );
}

export default SearchBooking;
